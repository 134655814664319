import styled from 'styled-components';
export const ProcessStyled = styled.div`
  // background-image:${({ theme }) =>
    `radial-gradient(circle at 50% 0%, ${theme.colors.gradients.a3e255b} 0%,${theme.colors.background} 100%)`};
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  box-shadow: ${({ theme }) => (theme.isDark ? '0 10px 20px 5px rgba(0, 0, 0, 0.03)' : 'none')};
  border-radius: 12px;
  padding: 0 8px;
  height: 36px;
  line-height: 34px;
  margin-right: 12px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  .stop {
    animation-play-state: paused;
  }
`;
export const ProcessListStyled = styled.div`
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  max-height: 390px;
  overflow-y: auto;
  .no_process {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6a6991;
    svg {
      width: 30px;
      margin-right: 10px;
    }
  }
  .flex {
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.background};
    border-radius: 12px;
    padding: 18px 20px;
    margin-top: 12px;
    cursor: pointer;
    &:first-child {
      margin-top: 0;
    }
    .loading-item {
      width: 36px;
      height: 36px;
      margin-right: 20px;
      &:before {
        content: '';
        border-radius: 18px 18px 0 0;
      }
    }
    h5 {
      margin-bottom: 2px;
      font-size: 14px;
    }
    p {
      font-size: 12px;
      color: #6a6991;
    }
  }
`;

export const ContractAccountInfoStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 12px 0 16px 10px;
  img {
    width: 28px;
    margin-right: 12px;
  }
  h5 {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 16px;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: start;
    padding-top: 4px;
    p {
      color: #6a6991;
      font-size: 12px;
      transition: all 0.3s ease;
    }
    svg {
      width: 14px;
      margin-top: -4px;
      margin-left: 4px;
      path {
        transition: all 0.3s ease;
      }
    }
    &:hover {
      p {
        color: ${({ theme }) => theme.colors.text};
      }
      svg {
        path {
          stroke: ${({ theme }) => theme.colors.text};
        }
      }
    }
  }
`;
