import { memo, useMemo } from 'react';
import { getDappImage } from 'utils';
import BigNumber from 'bignumber.js';
import SizedImg from './sizedImg';
import { ITradeType } from '../constants';
import { IChainItemType } from 'lifiConfig/IChainType';
import { getWalletConfig } from 'lifiConfig/getWalletConfig';
import { IStep } from './RouterComp';
import { BIG_TEN } from 'utils/bigNumber';
import { STATIC_BASE_URL } from 'config';

const LineTypeV2 = ({
  value,
  fromchain,
  fromDecimals,
  collapsed,
  tochain,
  fromtokenaddress,
  fromtokensymbol,
  totokenaddress,
  toDecimals,
  totokensymbol,
  endValue,
  label,
  step,
  fromdappname,
  todappname,
  totokenlogo,
}) => {
  const mTradeType = useMemo(() => {
    if (!fromchain || !fromtokenaddress || !tochain) {
      return null;
    }
    if (fromchain === tochain) {
      if (fromtokenaddress === totokenaddress) {
        return ITradeType.type_0;
      }
    }
    return ITradeType.type_1;
  }, [fromchain, fromtokenaddress, tochain, totokenaddress]);

  if (!mTradeType) {
    return null;
  }
  return (
    <>
      <div className="line_bg">
        <LineCompV2
          collapsed={collapsed}
          tradetype={mTradeType}
          startvalue={value}
          fromchain={fromchain}
          fromtokensymbol={fromtokensymbol}
          totokensymbol={totokensymbol}
          totokenlogo={totokenlogo}
          tochain={tochain}
          endvalue={endValue}
          step={step as IStep}
          label={label}
          fromdappname={fromdappname}
          todappname={todappname}
          fromDecimals={fromDecimals}
          toDecimals={toDecimals}
        />
      </div>
    </>
  );
};

export const LineCompV2 = ({
  collapsed,
  tradetype,
  startvalue,
  fromchain,
  fromtokensymbol,
  totokensymbol,
  tochain,
  endvalue,
  label,
  step,
  fromdappname,
  todappname,
  fromDecimals,
  toDecimals,
  totokenlogo,
}) => {
  const fromChainObj = useMemo(() => {
    return getWalletConfig(fromchain);
  }, [fromchain]);
  const toChainObj = useMemo(() => {
    return getWalletConfig(tochain);
  }, [tochain]);

  if (tradetype === ITradeType.type_0) {
    if (!collapsed) {
      return (
        <div className="type_0">
          <div className="type_inner">
            <ChainCard
              value={startvalue}
              decimals={fromDecimals}
              currency={fromtokensymbol}
              chain={fromChainObj}
              dapp={fromdappname}
            />
            <LabelCard label={label} />
            <ChainCard
              value={endvalue}
              decimals={toDecimals}
              currency={totokensymbol}
              chain={toChainObj}
              dapp={todappname}
            />
          </div>
          <div className="bg">
            <div className="bg_line">
              <div className="bg_line_inner"></div>
            </div>
            <div className="bg_arrow_right"></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="type">
          <div className="type_inner_big_top">
            <div className="type_inner_big_top_bg">
              <ChainCard
                decimals={fromDecimals}
                value={startvalue}
                currency={fromtokensymbol}
                chain={fromChainObj}
                dapp={fromdappname}
              />
              <ChainCard
                decimals={toDecimals}
                value={endvalue}
                currency={totokensymbol}
                chain={toChainObj}
                dapp={todappname}
              />
            </div>
            {collapsed ? null : <div className="bg_arrow_left"></div>}
          </div>
          <div className="type_inner_big_bottom center">
            <LabelCard label={label} />
          </div>
          <div className="bg center">
            <div className="bg_line">
              <div className="bg_line_inner"></div>
            </div>
            {collapsed ? <div className="bg_arrow_top"></div> : null}
          </div>
        </div>
      );
    }
  }
  return (
    <div className="type">
      <div className="type_inner_big_top">
        <div
          className={`type_inner_big_top_bg ${
            label.toLowerCase() === 'withdraw' ? 'type_inner_big_top_bg_layZero' : ''
          }`}
        >
          <ChainCard
            decimals={fromDecimals}
            value={startvalue}
            currency={fromtokensymbol}
            chain={fromChainObj}
            dapp={fromdappname}
          />
          {label.toLowerCase() === 'withdraw' ? (
            <div className="layZero">
              <div className="layZero_bg">
                <div className="layZero_bg_inner">
                  <SizedImg src={`${STATIC_BASE_URL}/images/chain/layerzero.png`} alt="layerzero" />
                </div>
              </div>
              <SizedImg src={`${STATIC_BASE_URL}/images/arrow01.svg`} alt="arrow" />
            </div>
          ) : null}
          <ChainCard
            decimals={toDecimals}
            value={endvalue}
            currency={totokensymbol}
            chain={toChainObj}
            dapp={todappname}
          />
        </div>
        {collapsed ? null : <div className="bg_arrow_left"></div>}
        {collapsed ? null : step.length >= 3 ? (
          <TokenCard
            chainId={tochain}
            addressLogo={totokenlogo}
            value={endvalue}
            currency={totokensymbol}
            decimals={toDecimals}
          />
        ) : null}
      </div>

      <div className={`type_inner_big_bottom ${step.length < 2 ? 'a1' : 'a2'}`}>
        {(step as IStep[])
          .filter((v) => v.type)
          .map((v, index) => {
            if (v.type === 'swap') {
              return (
                <SwapCard
                  key={`${index}`}
                  fromAddress={v.fromToken.address}
                  fromAddressLogo={v.fromToken.logoURI}
                  toaddressusdc={v.toToken.address}
                  toaddressusdclogo={v.toToken.logoURI}
                  label={v.toolDetails.name}
                />
              );
            } else if (v.type === 'cross') {
              return <BridgeCard key={index} bridgename={v.toolDetails.name} bridgeLogo={v.toolDetails.logoURI} />;
            } else {
              return (
                <TokenCard
                  chainId={`${v.toChainId}`}
                  addressLogo={v?.toToken?.logoURI}
                  value={v.toAmount}
                  currency={v?.toToken?.symbol}
                  decimals={v?.toToken?.decimals}
                  key={index}
                />
              );
            }
          })}
      </div>
      <div className="bg">
        <div className="bg_line">
          <div className="bg_line_inner"></div>
        </div>
        {collapsed ? <div className="bg_arrow_top"></div> : null}
      </div>
    </div>
  );
};

const ChainCard = ({
  value,
  decimals,
  currency,
  chain,
  dapp,
}: {
  value: string;
  decimals: number;
  currency: string;
  chain: IChainItemType;
  dapp?: string;
}) => {
  const _value = useMemo(() => {
    return Number(
      new BigNumber(value || '0').div(BIG_TEN.pow(new BigNumber(decimals))).toFixed(4, BigNumber.ROUND_DOWN),
    ).toLocaleString('en-US', {
      maximumFractionDigits: 4,
    });
  }, [decimals, value]);
  return (
    <div className="chain_card">
      <SizedImg src={chain?.logoURI} alt={chain.name} />
      <div className="fr">
        <h4>
          {_value}
          {currency}
        </h4>
        <p>
          On {chain.name}
          {dapp ? <SizedImg src={getDappImage(dapp)} alt={dapp} /> : null}
        </p>
      </div>
    </div>
  );
};

const LabelCard = ({ label }: { label: string }) => {
  return <p className="label_card">{label}</p>;
};
const SwapCard = ({
  fromAddress,
  fromAddressLogo,
  toaddressusdc,
  toaddressusdclogo,
  label,
}: {
  fromAddress: string;
  fromAddressLogo: string;
  toaddressusdc: string;
  toaddressusdclogo: string;
  label: string;
}) => {
  return (
    <div className="label_card ">
      <SizedImg src={fromAddressLogo} alt={fromAddress} />
      <SizedImg src={toaddressusdclogo} alt={toaddressusdc} />
      <p>{label}</p>
    </div>
  );
};
const TokenCard = ({
  chainId,
  addressLogo,
  value,
  currency,
  decimals,
}: {
  chainId: string;
  addressLogo: string;
  value: string;
  currency: string;
  decimals: number;
}) => {
  const _value = useMemo(() => {
    return Number(
      new BigNumber(value || '0').div(BIG_TEN.pow(new BigNumber(decimals))).toFixed(18, BigNumber.ROUND_DOWN),
    ).toLocaleString('en-US', {
      maximumFractionDigits: 2,
    });
  }, [decimals, value]);
  const chainObj = getWalletConfig(chainId);
  return (
    <div className="label_card">
      <SizedImg src={addressLogo} alt="" />
      <div>
        <p>
          {_value}
          {currency}
        </p>
        <p>On {chainObj.name}</p>
      </div>
    </div>
  );
};
const BridgeCard = ({ bridgeLogo, bridgename }: { bridgeLogo: string; bridgename: string }) => {
  return (
    <div className="label_card">
      <SizedImg src={bridgeLogo} alt={bridgename} />
      <p>{bridgename}</p>
    </div>
  );
};

export default memo(LineTypeV2);
