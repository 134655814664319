import { CHAINID } from 'config/constants/chain_config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useSafeContractStateAddress, getSafeProxyFactoryContractAddress } from './fetch/safeContractServices';
import { calculateUserAddress } from './utils/utils';
import { SafeContractState } from 'state/types';
import { sigValidationModuleAddress, ModuleEnablerAddress } from 'config/vault/address';
import { IChainItemType } from 'lifiConfig/IChainType';

export const fetchSafeContractAsync = createAsyncThunk<{}, { chainList: IChainItemType[]; account: string }>(
  'safeContract/fetchSafeContractAsync',
  async ({ chainList, account }) => {
    // async ({ chainList, account: _ }) => {
    // const account = '0x41BA3387E1a5a592E27B9EE33935957CE5F872C1';
    const safeProxyFactoryContractAddresses = await getSafeProxyFactoryContractAddress();
    const safeContractAddresses = await useSafeContractStateAddress();
    const obj = {
      [account]: {},
    };
    for (const res of chainList) {
      const chainObj = res;
      const safeProxyFactoryContractAddress = safeProxyFactoryContractAddresses.networkAddresses[`${chainObj.id}`];
      const safeContractAddress = safeContractAddresses.networkAddresses[`${chainObj.id}`];
      const chainId: CHAINID = `${chainObj.id}` as CHAINID;
      const MODULE = sigValidationModuleAddress[chainId];
      const MODULE_ENABLER = ModuleEnablerAddress[chainId];
      if (safeProxyFactoryContractAddress && safeContractAddress && MODULE && MODULE_ENABLER) {
        // SAFE_FACTORY_ADDRESS
        const from = safeProxyFactoryContractAddress;
        // SAFE_ADDRESS
        const safeSingleton = safeContractAddress;

        const address = calculateUserAddress({
          safeSingleton,
          from,
          account,
          MODULE,
          MODULE_ENABLER,
          chain: chainId,
        });
        obj[account] = {
          ...obj[account],
          [chainId]: address,
        };
      }
    }
    return obj;
  },
);
export const SafeContractExtraReducers = (builder) => {
  builder.addCase(fetchSafeContractAsync.fulfilled, (state: SafeContractState, action) => {
    state.safeAddress = action.payload;
  });
};
