import React from 'react';
import { SvgProps } from './types';

const SuccessIcon: React.FC<SvgProps> = (props) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd">
        <path className="one_path" d="M8 0A8 8 0 1 0 8 16A8 8 0 1 0 8 0Z" />
        <path
          className="two_path"
          d="M6.61111111,10.8888889 C6.15087382,10.8888889 5.77777778,10.5157928 5.77777778,10.0555556 C5.77777778,9.59531826 6.15087382,9.22222222 6.61111111,9.22222222 L9.11077778,9.22155556 L9.11111111,3.38888889 C9.11111111,2.9286516 9.48420715,2.55555556 9.94444444,2.55555556 C10.4046817,2.55555556 10.7777778,2.9286516 10.7777778,3.38888889 L10.7777778,9.22222222 C10.7777778,10.0942508 10.1080652,10.8098596 9.25491749,10.8827712 L9.11111111,10.8888889 L6.61111111,10.8888889 Z"
          transform="rotate(45 8.277778 6.722222)"
        />
      </g>
    </svg>
  );
};
export default SuccessIcon;
