export const tabText = ['Deposit', 'Withdraw'];
export const chosedTokenLocalStorageKey = 'Chosed_Token_Local_Storage_Key';
export const chosedTokenStorageKey = 'Chosed_Token_Storage_Key';

export enum ITradeType {
  'type_0' = '0', // [Bsc AToken] -> [Bsc AToken DApp]
  'type_1' = '1', // [Bsc BToken] -> Swap -> [Bsc AToken] -> [Bsc AToken DApp]
  'type_2' = '2', // [Ethereum AToken] -> Multichain -> [Bsc AToken DApp]
  'type_3' = '3', // [Ethereum BToken] -> Multichain -> [Bsc BToken] -> Swap -> [BSC AToken] -> [BSC AToken Dapp]
  'type_4' = '4', // [Ethereum BToken] -> swap -> [Ethereum AToken] -> Multichain -> [Bsc AToken]
}
