import { ErrorIcon } from '@my/ui';
import LoadingIcon from 'components/svg/loading';
import SuccessIcon from 'components/svg/SuccessIcon';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { ModalStyled } from './styled';
import { CHAINID } from 'config/constants/chain_config';
import { useThemeContext } from 'state/user/hook';
import { AvaultTask, TaskStatus } from 'state/vaultHistory/types';
import { getHistroyByKey, useExInfoFnByKey } from '../hooks/useExInfo';
import { LineStyledV2 } from 'views/Trade/components/LineStyledV2';
import LineTypeV2 from 'views/Trade/components/LineTypeV2';
import IconLink from 'components/svg/IconLink';
import { getWalletConfig } from 'lifiConfig/getWalletConfig';
import { getLink } from 'utils/getLink';
import { CopyButton } from 'components/CopyButton';
export interface IShowStatusModal {
  collapsed: boolean;
  title?: string;
  modeData?: AvaultTask;
  taskKey?: string;
  onDismiss?: any;
  account: string;
}
const ShowStatusModal: FC<IShowStatusModal> = ({ account, title, collapsed, taskKey, modeData, onDismiss }) => {
  const [item, setItem] = useState<AvaultTask>();
  const theme = useThemeContext();
  const { exInfo, step, tx_arr } = useExInfoFnByKey(item, setItem, taskKey, modeData);
  console.log({ exInfo });
  // setInterval get balance
  useEffect(() => {
    let interval = null;
    if (exInfo && (exInfo.status === TaskStatus.PENDING || exInfo.status === TaskStatus.PROCESSING)) {
      // update
      interval = setInterval(async () => {
        const _taskKey = taskKey ? taskKey : `task-${account}-${exInfo.createTime}`;
        const res = await getHistroyByKey(_taskKey);
        if (res && res.length) {
          const _item = res[0];
          if (_item.taskStatus !== exInfo.status) {
            setItem(res[0]);
            clearInterval(interval);
          }
        }
      }, 10000);
    }
    return () => (interval ? clearInterval(interval) : null);
  }, [exInfo, account, taskKey]);
  if (!exInfo || !step) return null;
  return (
    <ModalStyled title="" headerPadding="4px 30px 0" onDismiss={onDismiss}>
      {exInfo.status === TaskStatus.FINISHED ? (
        <SuccessIcon className="svg" />
      ) : exInfo.status === TaskStatus.ERROR ? (
        <ErrorIcon className="svg error" />
      ) : (
        <LoadingIcon className="svg" />
      )}
      <h3 className="title">{title ?? 'Real-Time Processing'}</h3>

      <LineStyledV2 backgroundColor={theme.colors.background02}>
        <LineTypeV2
          value={exInfo.startvalue}
          fromDecimals={exInfo.fromdecimals}
          endValue={exInfo.endvalue}
          toDecimals={exInfo.toDecimals}
          fromchain={`${exInfo.fromChainId}` as CHAINID}
          fromtokensymbol={exInfo.fromtokensymbol}
          fromtokenaddress={exInfo.fromtokenaddress}
          // chosedVault={chosedVault}
          collapsed={collapsed}
          tochain={`${exInfo.tochainId}` as CHAINID}
          totokenaddress={exInfo.totokenaddress}
          totokensymbol={exInfo.totokensymbol}
          step={step}
          label={'deposit'}
          fromdappname={exInfo.fromdappname}
          todappname={exInfo.todappname}
          totokenlogo={exInfo.totokenaddresslogo}
        />
      </LineStyledV2>
      <div className="copy_btn">
        <CopyButton
          ParantProps={'div'}
          width="16px"
          buttonColor="#fff"
          text={`task-${account}-${exInfo.createTime}`}
          tooltipMessage="Copy Key Success"
          tooltipTop={10}
          tooltipRight={0}
          tooltipFontSize={12}
        >
          <p>Copy Key</p>
        </CopyButton>
      </div>
      <div className="flex">
        {tx_arr.length ? (
          <TxList
            bridgekey={exInfo.bridgekey}
            tochainId={exInfo.tochainId}
            tx_arr={tx_arr}
            fromchainId={exInfo.fromChainId}
          />
        ) : null}

        <div className="detail">
          <p>Gas Fee: ≈${exInfo.gastotalfee}</p>
          <p>Arrival time：~{exInfo.arrivaltotaltime}s</p>
        </div>
      </div>
      {exInfo.status === TaskStatus.PROCESSING || exInfo.status === TaskStatus.PENDING ? (
        <p className="grey_text">This process will be displayed in my order list when it is closed or finished</p>
      ) : null}

      {exInfo.status === TaskStatus.ERROR ? <p className="grey_text">This process is Error - {exInfo.msg}</p> : null}
    </ModalStyled>
  );
};
const TxList = ({
  tx_arr,
  fromchainId,
  bridgekey,
  tochainId,
}: {
  bridgekey: string;
  tochainId: CHAINID;
  fromchainId: CHAINID;
  tx_arr: string[];
}) => {
  const [toTxHash, setToTxHash] = useState();
  const [status, setStatus] = useState();
  const fromChainObj = useMemo(() => {
    return getWalletConfig(fromchainId);
  }, [fromchainId]);
  const toChainObj = useMemo(() => {
    return getWalletConfig(tochainId);
  }, [tochainId]);
  useEffect(() => {
    if (bridgekey && tx_arr && tochainId !== fromchainId) {
      (async () => {
        const fetchLifiStatus = async () => {
          const response = await fetch(
            `https://li.quest/v1/status?bridge=${bridgekey}&fromChain=${fromchainId}&toChain=${tochainId}&txHash=${tx_arr[0]}`,
          );
          const data = await response.json();
          return data;
        };
        const res = await fetchLifiStatus();
        if (res?.receiving?.txLink) {
          setToTxHash(res?.receiving?.txLink);
        } else if (res?.status) {
          setStatus(res?.status);
        }
      })();
    }
  }, [bridgekey, tochainId, fromchainId, tx_arr]);
  return (
    <div className="tx_list">
      <a
        href={getLink(tx_arr[0], 'transaction', fromchainId)}
        title={fromChainObj.name}
        target="_blank"
        rel="noreferrer"
      >
        {fromChainObj.name} TX
        <IconLink />
      </a>
      {toTxHash || status ? (
        <>
          <div className="line_"></div>
          {toTxHash ? (
            <a href={toTxHash} title={tochainId} target="_blank" rel="noreferrer">
              {toChainObj.name} TX
              <IconLink />
            </a>
          ) : status ? (
            <p>
              {toChainObj.name} TX
              <IconLink />
            </p>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
export default memo(ShowStatusModal);
