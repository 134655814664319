import BigNumber from 'bignumber.js';
import { CHAINID } from 'config/constants/chain_config';
import { CHAINLISTID } from 'lifiConfig/getWalletConfig';
import { useMemo } from 'react';
import { IMultiToken, IMultiTokenItem } from 'state/types';
import { IVault } from 'state/vault/types';
import { BIG_TEN } from 'utils/bigNumber';
import { getLocalStorage } from 'utils/localStorage';
import { symbolUtils } from 'utils/symbolFormmat';
import { IVaultAccountBalanceInfoItem } from 'views/VaultMulti/components/MultiVaultAccountBalanceInfo/MultiVaultAccountBalanceInfoItem';
import { chosedTokenLocalStorageKey } from '../constants';

export function useGetChainAndToken({
  account,
  multiToken,
  multiTokenConfig,
  fromchain,
  tochain,
  chosedTokenAddress,
  chosedVault,
  accountBalanceInfo,
  vaultIsTo = false,
  isClaim = false,
  safeAddress,
}: {
  account: string;
  multiToken: IMultiToken;
  multiTokenConfig: IMultiToken;
  fromchain: CHAINID;
  tochain: CHAINID;
  chosedTokenAddress?: string;
  chosedVault?: IVault;
  vaultIsTo?: boolean;
  isClaim?: boolean;
  accountBalanceInfo?: IVaultAccountBalanceInfoItem[];
  safeAddress?: Record<string, Record<CHAINID, string>>;
}) {
  return useMemo(() => {
    const _multiToken =
      account && multiToken && Object.keys(multiToken).length ? multiToken || multiTokenConfig : multiTokenConfig;
    if (!_multiToken) {
      return {};
    }
    const chainList = CHAINLISTID;
    let _fromchain = vaultIsTo ? fromchain : chosedVault?.chainkey || fromchain;
    let _tochain = vaultIsTo ? chosedVault?.chainkey || tochain : tochain;
    if (!chainList.includes(_fromchain)) {
      _fromchain = chainList[0] as CHAINID;
    }
    if (!chainList.includes(_tochain)) {
      _tochain = chainList[0] as CHAINID;
    }
    const fromtokenList: IMultiTokenItem[] = _multiToken[_fromchain];
    const toTokenList: IMultiTokenItem[] = _multiToken[_tochain];
    // if (!vaultIsTo) {
    //   toTokenList = toTokenList.filter((v) => v.address !== ZERO_ADDRESS);
    // }
    if (!fromtokenList) {
      return {};
    }
    let fromtoken: IMultiTokenItem = null;
    let toToken: IMultiTokenItem = null;
    if (chosedTokenAddress) {
      console.log({ fromtokenList, _fromchain, _tochain, chosedTokenAddress });
      fromtoken = fromtokenList.filter(
        (v) => (v?.address || '-').toLowerCase() === chosedTokenAddress.toLowerCase(),
      )[0];
      if (!fromtoken) {
        return {};
      }
      const _symbol = symbolUtils(fromtoken.symbol);
      const chosedTokenLocalStorage = getLocalStorage(chosedTokenLocalStorageKey) || {};
      const chosedTokenKey = chosedTokenLocalStorage[tochain] ?? _symbol;
      const _token = toTokenList.filter((v) => v.symbol.toLowerCase() === chosedTokenKey.toLowerCase())[0];
      toToken = _token || toTokenList[0];
    }
    if (chosedVault && (vaultIsTo || safeAddress || !account)) {
      if (vaultIsTo) {
        if (chosedVault.vault) {
          toToken = {
            symbol: chosedVault.vault.vaultsymbol,
            address: chosedVault.contractAddress,
            decimals: chosedVault.vault.decimals,
            logoURI: chosedVault.vault.vaultlogoURI,
            chainId: chosedVault.chainkey,
            name: chosedVault.vault.vaultsymbol,
            priceUSD: '1',
            balances: {
              [account]: {
                number: '0',
                localNumber: '0',
              },
            },
          };
        } else if (chosedVault.stake) {
          toToken = {
            symbol: chosedVault.stake.stakesymbol,
            address: chosedVault.contractAddress,
            decimals: chosedVault.stake.wantaddressdecimals,
            logoURI: chosedVault.stake.stakelogoURI,
            chainId: chosedVault.chainkey,
            name: chosedVault.stake.stakesymbol,
            priceUSD: '1',
            balances: {
              [account]: {
                number: '0',
                localNumber: '0',
              },
            },
          };
        }
        const _symbol = symbolUtils(toToken.symbol);
        const chosedTokenLocalStorage = getLocalStorage(chosedTokenLocalStorageKey) || {};
        const chosedTokenKey = chosedTokenLocalStorage[fromchain] ?? _symbol;
        const _token = fromtokenList.filter((v) => v.symbol.toLowerCase() === chosedTokenKey.toLowerCase())[0];
        // const wantAddress = chosedVault.vault.wantaddress.toLocaleLowerCase();
        fromtoken = _token || fromtokenList[0];
      } else {
        // const accountKey = vaultIsTo ? account.toLowerCase() : _fromchain === _tochain ? account.toLowerCase() : 'xx';
        const ___balance = (accountBalanceInfo ?? []).filter(
          (v) =>
            v.contractAddress.toLowerCase() === chosedVault.contractAddress.toLowerCase() &&
            v.chain === chosedVault.chainkey,
        );
        const __balance = ___balance.map((v) => v.account.toLowerCase());
        const _accountIndex = __balance.indexOf(account?.toLowerCase());
        let _childIndex = 0;
        let _childAccount;
        if (_accountIndex > -1) {
          const copyArr = __balance.slice();
          copyArr.splice(_accountIndex, 1);
          _childAccount = copyArr[0];
          _childIndex = __balance.indexOf(_childAccount);
        }

        const decimals = chosedVault.vault
          ? chosedVault.vault.decimals
          : isClaim
          ? chosedVault.stake.earnedaddresssymbol[0].decimals
          : chosedVault.stake.wantaddressdecimals;

        const balanceKeyParentValue = isClaim
          ? ___balance[_accountIndex]?.rewardTokenAmountNum || '0'
          : ___balance[_accountIndex]?.withdrawAble || '0';
        console.log({ __balance, balanceKeyParentValue, ___balance, _accountIndex });
        const bgAccount = new BigNumber(balanceKeyParentValue)
          .div(BIG_TEN.pow(new BigNumber(decimals)))
          .toFixed(8, BigNumber.ROUND_DOWN);
        const bgStrAccount = Number(
          new BigNumber(balanceKeyParentValue)
            .div(BIG_TEN.pow(new BigNumber(decimals)))
            .toFixed(4, BigNumber.ROUND_DOWN),
        ).toLocaleString('en-US', {
          maximumFractionDigits: 4,
        });
        const balanceKeyChildValue = isClaim
          ? ___balance[_childIndex]?.rewardTokenAmountNum || '0'
          : ___balance[_childIndex]?.withdrawAble || '0';
        const bgChild = new BigNumber(balanceKeyChildValue)
          .div(BIG_TEN.pow(new BigNumber(decimals)))
          .toFixed(8, BigNumber.ROUND_DOWN);
        const bgStrChild = Number(
          new BigNumber(balanceKeyChildValue)
            .div(BIG_TEN.pow(new BigNumber(decimals)))
            .toFixed(4, BigNumber.ROUND_DOWN),
        ).toLocaleString('en-US', {
          maximumFractionDigits: 4,
        });
        if (chosedVault.vault) {
          fromtoken = {
            symbol: chosedVault.vault.vaultsymbol,
            address: chosedVault.contractAddress,
            decimals: chosedVault.vault.decimals,
            logoURI: chosedVault.vault.vaultlogoURI,
            chainId: chosedVault.chainkey,
            name: chosedVault.vault.vaultsymbol,
            priceUSD: '1',
            balances: {
              [_accountIndex > -1 ? ___balance[_accountIndex]?.account.toLowerCase() : '-']: {
                number: bgAccount,
                localNumber: bgStrAccount,
              },
              [_childIndex > -1 ? ___balance[_childIndex]?.account.toLowerCase() : '-']: {
                number: bgChild,
                localNumber: bgStrChild,
              },
            },
          };
        } else if (chosedVault.stake) {
          fromtoken = {
            symbol: chosedVault.stake.stakesymbol,
            address: chosedVault.contractAddress,
            decimals: chosedVault.stake.wantaddressdecimals,
            logoURI: chosedVault.stake.stakelogoURI,
            chainId: chosedVault.chainkey,
            name: chosedVault.stake.stakesymbol,
            priceUSD: '1',
            balances: {
              [_accountIndex > -1 ? ___balance[_accountIndex]?.account.toLowerCase() : '-']: {
                number: bgAccount,
                localNumber: bgStrAccount,
              },
              [_childIndex > -1 ? ___balance[_childIndex]?.account.toLowerCase() : '-']: {
                number: bgChild,
                localNumber: bgStrChild,
              },
            },
          };
          if (isClaim) {
            const earnedToken = chosedVault.stake.earnedaddresssymbol[0];
            fromtoken.symbol = earnedToken.symbol;
            fromtoken.address = earnedToken.address;
            fromtoken.decimals = earnedToken.decimals;
            fromtoken.logoURI = earnedToken.logoURI;
          }
        }

        const _symbol = symbolUtils(fromtoken.symbol);
        const chosedTokenLocalStorage = getLocalStorage(chosedTokenLocalStorageKey) || {};
        const chosedTokenKey = chosedTokenLocalStorage[tochain] ?? _symbol;
        const _token = toTokenList.filter((v) => v.symbol.toLowerCase() === chosedTokenKey.toLowerCase())[0];
        toToken = _token || toTokenList[0];
      }
    }
    // fromtokenList.sort(
    //   (a, b) => Number(Object.values(b.balances)[0].number) - Number(Object.values(a.balances)[0].number),
    // ),
    return {
      chainList,
      fromtokenList,
      fromtoken,
      fromchain: _fromchain,
      toTokenList,
      toToken,
      tochain: _tochain,
    };
  }, [
    isClaim,
    multiToken,
    vaultIsTo,
    fromchain,
    multiTokenConfig,
    account,
    chosedTokenAddress,
    tochain,
    chosedVault,
    accountBalanceInfo,
    safeAddress,
  ]);
}
