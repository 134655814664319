import { CHAINID } from 'config/constants/chain_config';
import { CHAINLISTID } from 'lifiConfig/getWalletConfig';
import MultiTokenConfigObj from 'lifiConfig/tokens.json';
import { IMultiTokenItem } from 'state/types';

const fetchVaultMultiToken = async () => {
  try {
    // const apiUrl = `${STATIC_BASE_URL}/api/multi_token.json`;
    // const r = await fetch(apiUrl);
    // const body = await r.json();
    const obj = {};
    CHAINLISTID.map((v) => {
      const tokenList = MultiTokenConfigObj.tokens[v];
      const key = v as CHAINID;
      obj[key] = tokenList.map(
        (token) =>
          ({
            address: token.address,
            symbol: token.symbol,
            decimals: token.decimals,
            chainId: token.chainId,
            name: token.name,
            coinKey: token.coinKey,
            logoURI: token.logoURI,
            priceUSD: token.priceUSD,
            balances: {},
          } as IMultiTokenItem),
      );
      return null;
    });
    return obj;
  } catch (e: any) {
    return {};
  }
};
export default fetchVaultMultiToken;
