import { CHAINID } from 'config/constants/chain_config';
import { SigValidationModuleABI } from 'config/vault/abi';
import { splitSignature } from 'ethers/lib/utils';
import { RPCApi } from 'server/RPCApi';
import { Operation } from 'views/Trade/types';

export async function getUserOperationV2({
  data,
  tochainId,
  _value,
  sigValidationModuleAddress,
  library,
  account,
  avatar,
  gasToken,
  gasTokenAmount,
  _callTo,
  chainkey,
}: {
  data: string;
  tochainId: number;
  _value: string;
  sigValidationModuleAddress: string;
  library: any;
  account: string;
  avatar: string;
  gasToken: string;
  gasTokenAmount: string;
  _callTo: string;
  chainkey: CHAINID;
}) {
  const rpcApi = new RPCApi({
    chainkey: chainkey,
  });
  const EIP712Domain = [
    { name: 'name', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'verifyingContract', type: 'address' },
  ];
  const domain = {
    name: 'Avault',
    version: '1',
    verifyingContract: sigValidationModuleAddress,
  };
  const params01 = {
    toChainId: tochainId, // uint256 tochainId;
    to: _callTo, // address to;
    value: _value, // uint256 value;
    data: data, // bytes data;
    gasToken, // address gasToken;
    gasTokenAmount, // uint256 gasTokenAmount;
    operation: Operation.DelegateCall, // Enum.Operation operation;
  };
  const [[_nonce]] = await rpcApi.multicall(SigValidationModuleABI, [
    {
      address: sigValidationModuleAddress,
      name: 'nonces',
      params: [avatar],
    },
  ]);
  const nonce = _nonce.toNumber();
  // ("SafeModuleTx(uint256 tochainId,address to,uint256 value,bytes data,address gasToken,uint256 gasTokenAmount,uint8 operation,uint256 nonce)");
  // only for child acc
  const signData = JSON.stringify({
    types: {
      EIP712Domain,
      SafeModuleTx: [
        { type: 'uint256', name: 'toChainId' },
        { type: 'address', name: 'to' },
        { type: 'uint256', name: 'value' },
        { type: 'bytes', name: 'data' },
        { type: 'address', name: 'gasToken' },
        { type: 'uint256', name: 'gasTokenAmount' },
        { type: 'uint8', name: 'operation' },
        { type: 'uint256', name: 'nonce' },
      ],
    },
    domain,
    primaryType: 'SafeModuleTx',
    message: {
      ...params01,
      nonce: nonce,
    },
  });
  // console.log({ account, signData });

  const { v, r, s } = await library
    .send('eth_signTypedData_v4', [account, signData])
    // .then(splitSignature)
    .then(splitSignature);
  // .then((signature) => {
  //   setSignatureData({
  //     v: signature.v,
  //     r: signature.r,
  //     s: signature.s,
  //     deadline: deadline.toNumber(),
  //   });
  // })
  const userOperation = {
    ...params01,
    v, // uint8 v;
    r, // bytes32 r;
    s, // bytes32 s;
  };
  return { userOperation, nonce };
}
