import { ArrowRightClickIcon } from 'components/svg/arrow_right';
import LoadingIcon from 'components/svg/loading';
import SuccessIcon from 'components/svg/SuccessIcon';
import { ErrorIcon } from '@my/ui';
import { useExInfoFn } from 'views/TransactionList/hooks/useExInfo';
import BigNumber from 'bignumber.js';
import { TaskStatus } from 'state/vaultHistory/types';

const SmallItem = ({ index, onPressModal, item }) => {
  const { exInfo } = useExInfoFn(item);
  return (
    <tr key={index} onClick={() => onPressModal(index)}>
      <td className="card_tr">
        <div className="card_top">
          <div className="fl">
            <img src={exInfo.fromtokenlogo} alt={exInfo.fromtokenaddress} />
            <h3>
              {new BigNumber(exInfo.startvalue).shiftedBy(-exInfo.fromdecimals).toFixed(2, BigNumber.ROUND_DOWN)}
              {exInfo.fromtokensymbol}
            </h3>
            <p>On {exInfo.fromchain}</p>
          </div>
          <ArrowRightClickIcon />
          <div className="fr">
            <img src={exInfo.totokenaddresslogo} alt="" />
            <h3>
              {new BigNumber(exInfo.endvalue).shiftedBy(-exInfo.toDecimals).toFixed(2, BigNumber.ROUND_DOWN)}
              {exInfo.totokensymbol}
            </h3>
            <p>On {exInfo.tochain}</p>
          </div>
        </div>
        <div className="card_bottom">
          {exInfo.fromchain !== exInfo.tochain ? (
            <div className="flex bridge">
              <img src={exInfo.bridgelogourl} alt={exInfo.bridgename} />
              <h4>{exInfo.bridgename}</h4>
            </div>
          ) : (
            '-'
          )}
          {exInfo.status === TaskStatus.ERROR ? (
            <div className="flex card card_grey">
              <ErrorIcon width={16} className="grey_err_path" />
              <p>Error</p>
            </div>
          ) : exInfo.status === TaskStatus.PROCESSING ? (
            <div className="flex card card_green">
              <LoadingIcon width={20} />
              <p>Pending</p>
            </div>
          ) : (
            <div className="flex card card_grey">
              <SuccessIcon width={16} />
              <p>Completed</p>
            </div>
          )}
          {/* <div className="tx_list">
            <a
              href={getLink(item.fromtx || item.from_tx, 'transaction', item.fromchain)}
              title={item.fromchain}
              target="_blank"
              rel="noreferrer"
            >
              {subStringAccount(item.fromtx)}
              <IconLink />
            </a>
          </div> */}
        </div>
      </td>
    </tr>
  );
};
export default SmallItem;
