import { CHAINID } from './chain_config';
export const tokens = {
  [CHAINID.ASTR_MAINNET]: {
    dot: {
      symbol: 'dot'.toUpperCase(),
      address: '0xffffffffffffffffffffffffffffffffffffffff',
      decimals: 10,
      projectLink: '',
    },
    aca: {
      symbol: 'ACA',
      address: '0xffffffff00000000000000010000000000000000',
      decimals: 12,
      projectLink: '',
    },
    muuu: {
      symbol: 'muuu'.toUpperCase(),
      address: '0xc5bcac31cf55806646017395ad119af2441aee37',
      decimals: 18,
      projectLink: '',
    },
    kgl: {
      symbol: 'kgl'.toUpperCase(),
      address: '0x257f1a047948f73158dadd03eb84b34498bcdc60',
      decimals: 18,
      projectLink: '',
    },
    lay: {
      symbol: 'lay'.toUpperCase(),
      address: '0xc4335b1b76fa6d52877b3046eca68f6e708a27dd',
      decimals: 18,
      projectLink: '',
    },
    ousd: {
      symbol: 'ousd'.toUpperCase(),
      address: '0x29f6e49c6e3397c3a84f715885f9f233a441165c',
      decimals: 18,
      projectLink: '',
    },
    bai: {
      symbol: 'bai'.toUpperCase(),
      address: '0x733ebcc6df85f8266349defd0980f8ced9b45f35',
      decimals: 18,
      projectLink: '',
    },
    nika: {
      symbol: 'nika'.toUpperCase(),
      address: '0x6df98e5fbff3041105cb986b9d44c572a43fcd22',
      decimals: 18,
      projectLink: '',
    },
    oru: {
      symbol: 'oru'.toUpperCase(),
      address: '0xcdb32eed99aa19d39e5d6ec45ba74dc4afec549f',
      decimals: 18,
      projectLink: '',
    },
    jpyc: {
      symbol: 'jpyc'.toUpperCase(),
      address: '0x431d5dff03120afa4bdf332c61a6e1766ef37bdb',
      decimals: 18,
      projectLink: '',
    },
    ibASTR: {
      symbol: 'ibASTR',
      address: '0x3BFcAE71e7d5ebC1e18313CeCEbCaD8239aA386c',
      decimals: 18,
      projectLink: '',
    },
    arsw: {
      symbol: 'ARSW',
      address: '0xDe2578Edec4669BA7F41c5d5D2386300bcEA4678',
      decimals: 18,
      projectLink: '',
    },
    astr: {
      symbol: 'WASTR',
      address: '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720',
      decimals: 18,
      projectLink: 'https://blockscout.com/astar/',
    },
    kaco: {
      symbol: 'KAC',
      address: '0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c',
      decimals: 18,
      projectLink: 'https://kaco.finance/',
    },

    usdt: {
      symbol: 'USDT',
      address: '0x3795C36e7D12A8c252A20C5a7B455f7c57b60283',
      decimals: 6,
      projectLink: 'https://tether.to/',
    },

    usdc: {
      symbol: 'USDC',
      address: '0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98',
      decimals: 6,
      projectLink: 'https://www.centre.io/usdc',
    },

    dai: {
      symbol: 'DAI',
      address: '0x6De33698e9e9b787e09d3Bd7771ef63557E148bb',
      decimals: 18,
      projectLink: 'https://www.centre.io/usdc',
    },

    eth: {
      symbol: 'WETH',
      address: '0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c',
      decimals: 18,
      projectLink: 'https://ethereum.org/en/',
    },

    bnb: {
      symbol: 'BNB',
      address: '0x7f27352D5F83Db87a5A3E00f4B07Cc2138D8ee52',
      decimals: 18,
      projectLink: 'https://bitcoin.org/',
    },
    busd: {
      symbol: 'BUSD',
      address: '0x4Bf769b05E832FCdc9053fFFBC78Ca889aCb5E1E',
      decimals: 18,
      projectLink: 'https://bitcoin.org/',
    },

    btc: {
      symbol: 'WBTC',
      address: '0xad543f18cFf85c77E140E3E5E3c3392f6Ba9d5CA',
      decimals: 8,
      projectLink: 'https://bitcoin.org/',
    },

    pkex: {
      symbol: 'PKEX',
      address: '0x1fE622E91e54D6AD00B01917351Ea6081426764A',
      decimals: 18,
      projectLink: 'https://www.paxos.com/busd/',
    },

    sdn: {
      symbol: 'SDN',
      address: '0x75364D4F779d0Bd0facD9a218c67f87dD9Aff3b4',
      decimals: 18,
      projectLink: 'https://www.paxos.com/busd/',
    },

    matic: {
      symbol: 'MATIC',
      address: '0xdd90E5E87A2081Dcf0391920868eBc2FFB81a1aF',
      decimals: 18,
      projectLink: 'https://www.paxos.com/busd/',
    },

    aave: {
      symbol: 'AAVE',
      address: '0xfcDe4A87b8b6FA58326BB462882f1778158B02F1',
      decimals: 18,
      projectLink: 'https://www.paxos.com/busd/',
    },

    crv: {
      symbol: 'CRV',
      address: '0x7756a83563f0f56937A6FdF668E7D9F387c0D199',
      decimals: 18,
      projectLink: 'https://www.paxos.com/busd/',
    },
  },
};
export const main_tokens = {
  [CHAINID.ASTR_MAINNET]: {
    symbol: 'ASTR',
    name: 'ASTR',
    decimals: 18,
    address: '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720',
    projectLink: 'https://blockscout.com/astar/',
  },
  [CHAINID.SDN_MAINNET]: {
    symbol: 'WSDN',
    name: 'Wrapped SDN',
    decimals: 18,
    address: '0x0f933Dc137D21cA519ae4C7E93f87a4C8EF365Ef',
    projectLink: 'https://shiden.subscan.io/',
  },
  [CHAINID.BSC_MAINNET]: {
    symbol: 'BNB',
    name: 'BNB Token',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    decimals: 18,
    projectLink: 'https://www.binance.com/',
  },
};

export const DEFAULT_Token = {
  [CHAINID.BSC_MAINNET]: {
    address: main_tokens[CHAINID.BSC_MAINNET].address,
    decimals: main_tokens[CHAINID.BSC_MAINNET].decimals,
    symbol: main_tokens[CHAINID.BSC_MAINNET].symbol,
    name: main_tokens[CHAINID.BSC_MAINNET].name,
  },

  [CHAINID.ASTR_MAINNET]: {
    address: main_tokens[CHAINID.ASTR_MAINNET].address,
    decimals: main_tokens[CHAINID.ASTR_MAINNET].decimals,
    symbol: main_tokens[CHAINID.ASTR_MAINNET].symbol,
    name: main_tokens[CHAINID.ASTR_MAINNET].name,
  },
};

export default tokens;
