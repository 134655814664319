import { SvgProps } from '@my/ui';
import { memo } from 'react';
import { useThemeContext } from 'state/user/hook';

const ArrowRight: React.FC<SvgProps> = (props) => {
  return (
    <svg viewBox="0 0 1024 1024" {...props}>
      <path d="M539.733333 251.733333l296.533334 243.2c6.4 4.266667 8.533333 10.666667 8.533333 19.2s-2.133333 12.8-8.533333 19.2l-296.533334 243.2c-6.4 4.266667-10.666667 6.4-14.933333 4.266667-4.266667-2.133333-6.4-6.4-6.4-14.933333V599.466667h-313.6c-8.533333 0-14.933333-2.133333-19.2-8.533334-6.4-6.4-8.533333-12.8-8.533333-19.2v-113.066666c0-8.533333 2.133333-14.933333 8.533333-19.2s12.8-8.533333 19.2-8.533334h313.6v-166.4c0-8.533333 2.133333-12.8 6.4-14.933333 4.266667-6.4 10.666667-4.266667 14.933333 2.133333z"></path>
    </svg>
  );
};

export const ArrowRightClickIcon: React.FC<SvgProps> = (props) => {
  const theme = useThemeContext();
  return (
    <svg viewBox="0 0 32 24" {...props}>
      <g transform="rotate(-90 12 10)" fill="none" fillRule="evenodd">
        <rect
          stroke="#25234C"
          strokeWidth="2"
          fill={theme.colors.secondary}
          x="-1"
          y="-1"
          width="22"
          height="30"
          rx="8"
        />
        <path
          d="M9.08252458,7.375 C9.08252458,6.68464406 9.64216864,6.125 10.3325246,6.125 C11.0228805,6.125 11.5825246,6.68464406 11.5825246,7.375 L11.5825246,14.875 L13.7109525,14.875 C13.9324797,14.875 14.1477364,14.9485583 14.3229358,15.0841293 C14.7597216,15.4221183 14.8398123,16.0501975 14.5018232,16.4869833 L14.5018232,16.4869833 L11.1233953,20.8529517 C11.0715796,20.9199135 11.0114696,20.9800235 10.9445079,21.0318391 C10.507722,21.3698282 9.87964291,21.2897375 9.54165388,20.8529517 L9.54165388,20.8529517 L6.16322592,16.4869833 C6.02765497,16.3117839 5.95409662,16.0965272 5.95409662,15.875 C5.95409662,15.3227153 6.40181188,14.875 6.95409662,14.875 L6.95409662,14.875 L9.08252458,14.875 L9.08252458,7.375 Z"
          fill={theme.colors.text}
        />
      </g>
    </svg>
  );
};
export default memo(ArrowRight);
