import { format } from 'date-fns';
import { memo } from 'react';
import LoadingIcon from 'components/svg/loading';
import SuccessIcon from 'components/svg/SuccessIcon';
import { ErrorIcon } from '@my/ui';
import { AvaultTask, TaskStatus } from 'state/vaultHistory/types';
import { useExInfoFn } from 'views/TransactionList/hooks/useExInfo';
import BigNumber from 'bignumber.js';
const BigItem = ({ index, onPressModal, item }: { index: number; onPressModal: any; item: AvaultTask }) => {
  const { exInfo } = useExInfoFn(item);
  console.log({ exInfo });
  if (!exInfo?.startvalue) {
    return null;
  }
  return (
    <tr key={index} onClick={(e) => onPressModal(index, e)}>
      <td>
        <div className="flex">
          <img src={exInfo.fromtokenlogo} alt={exInfo.fromtokenaddress} />
          <div className="fl">
            <h3>
              {exInfo.startvalue !== 'NaN'
                ? new BigNumber(exInfo.startvalue).shiftedBy(-exInfo.fromdecimals).toFixed(2, BigNumber.ROUND_DOWN)
                : ''}
              {exInfo.fromtokensymbol}
            </h3>
            <p>On {exInfo.fromchain}222</p>
          </div>
        </div>
      </td>
      <td>
        <div className="flex">
          <img src={exInfo.totokenaddresslogo} alt="" />
          <div className="fl">
            <h3>
              {exInfo.endvalue !== 'NaN'
                ? new BigNumber(exInfo.endvalue).shiftedBy(-exInfo.toDecimals).toFixed(2, BigNumber.ROUND_DOWN)
                : ''}
              {exInfo.totokensymbol}
            </h3>
            <p>On {exInfo.tochain}</p>
          </div>
        </div>
      </td>
      <td>
        {exInfo.fromchain !== exInfo.tochain ? (
          <div className="flex bridge">
            <img src={exInfo.bridgelogourl} alt={exInfo.bridgename} />
            <h4>{exInfo.bridgename}</h4>
          </div>
        ) : (
          '-'
        )}
      </td>
      <td>
        {exInfo.status === TaskStatus.ERROR ? (
          <div className="flex card card_grey">
            <ErrorIcon width={16} className="grey_err_path" />
            <p>Error</p>
          </div>
        ) : exInfo.status === TaskStatus.PROCESSING ? (
          <div className="flex card card_green">
            <LoadingIcon width={20} />
            <p>Pending</p>
          </div>
        ) : (
          <div className="flex card card_grey">
            <SuccessIcon width={16} />
            <p>Completed</p>
          </div>
        )}
      </td>
      <td>
        <p>{format(new Date(Number(exInfo.createTime) * 1000), 'HH:mm')}</p>
        <p>{format(new Date(Number(exInfo.createTime) * 1000), 'yyyy-MM-dd')}</p>
      </td>
    </tr>
  );
};
export default memo(BigItem);
