import BigNumber from 'bignumber.js';
import { BASE_URL } from 'config';
import { useEffect, useMemo } from 'react';
import { AvaultTask, SubTaskType } from 'state/vaultHistory/types';
import { BIG_TEN } from 'utils/bigNumber';
export const useExInfoFnByKey = (
  item: AvaultTask,
  setItem: React.Dispatch<React.SetStateAction<AvaultTask>>,
  taskKey: string,
  modeData: AvaultTask,
) => {
  useEffect(() => {
    (async () => {
      if (modeData) {
        setItem(modeData);
      } else {
        if (taskKey) {
          const res = await getHistroyByKey(taskKey);
          if (res && res.length) {
            setItem(res[0]);
          }
        }
      }
    })();
  }, [taskKey, setItem, modeData]);
  return useExInfoFn(item);
};

export const useExInfoFn = (item: AvaultTask) => {
  return useMemo(() => {
    if (item) {
      const arr = [];
      const tx_arr = [];
      let baseexInfo: any = {};
      for (let i = 0; i < item.subTasks.length; i++) {
        const subTasks = item.subTasks[i];
        if (subTasks.txHash && subTasks.txHash.length && subTasks.type !== SubTaskType.USERTX) {
          tx_arr.push(subTasks.txHash);
        }
        for (let k = 0; k < subTasks.actions.length; k++) {
          const actions = subTasks.actions[k];
          arr.push({
            ...actions,
            ...actions?.exInfo,
          });
          if (!Object.values(baseexInfo).length && actions.exInfo && actions.exInfo.startvalue) {
            baseexInfo = actions.exInfo;
          }
        }
      }
      const bridge = arr.filter((v) => v.type === 'cross')[0];
      if (baseexInfo.startvalue) {
        const _item = {
          fromtokenlogo: baseexInfo.fromtokenlogo.toLowerCase(),
          fromtokenaddress: baseexInfo.fromtokenaddress,
          startvalue: new BigNumber(baseexInfo.startvalue).times(BIG_TEN.pow(baseexInfo.fromdecimals)).toFixed(0),
          fromtokensymbol: baseexInfo.fromtokensymbol,
          fromchain: baseexInfo.fromchain,
          fromChainId: baseexInfo.fromChainId,
          tochainId: baseexInfo.tochainId,
          fromdecimals: baseexInfo.fromdecimals,
          endvalue: new BigNumber(baseexInfo?.endvalue ?? baseexInfo.startvalue)
            .times(BIG_TEN.pow(baseexInfo.toDecimals))
            .toFixed(0),
          toDecimals: baseexInfo.toDecimals,
          totokenaddress: baseexInfo.totokenaddress,
          totokensymbol: baseexInfo.totokensymbol,
          fromdappname: baseexInfo.fromdappname,
          todappname: baseexInfo.todappname,

          // todo
          gastotalfee: baseexInfo.gastotalfee,
          arrivaltotaltime: baseexInfo.arrivaltotaltime,

          totokenaddresslogo: baseexInfo.totokenaddresslogo.toLowerCase(),
          toaddressusdcsymbol: baseexInfo.toaddressusdcsymbol,
          tochain: baseexInfo.tochain,
          bridgekey: bridge?.toolDetails.key,
          bridgename: bridge?.toolDetails.name,
          bridgelogourl: bridge?.toolDetails.logoURI,
          status: item.taskStatus,
          createTime: item.createTime,
          msg: item.msg,
        };

        return { exInfo: _item, step: arr, tx_arr };
      }
    }
    return { exInfo: null, step: null, tx_arr: null };
  }, [item]);
};

export const getHistroyByKey = async (key: string) => {
  try {
    const apiUrl = `${BASE_URL}/api/v0/task?key=${key}`;
    const r = await fetch(apiUrl);
    const body = await r.json();
    return body.data;
  } catch (e: any) {
    return {};
  }
};
