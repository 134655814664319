import { Modal } from '@my/ui';
import styled from 'styled-components';

export const VaultMultiTransactionListStyled = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0 30px;
  }
  .tab_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 30px auto 24px;
    transition: all 0.3s ease;
    cursor: pointer;
    ${({ theme }) => theme.mediaQueries.md} {
      margin: 60px auto 70px;
    }
    h2 {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 16px;
      ${({ theme }) => theme.mediaQueries.md} {
        font-size: 36px;
        margin-bottom: 0;
      }
    }
    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 248px;
      background-color: ${({ theme }) => theme.colors.background02};
      border: 1px solid ${({ theme }) => theme.colors.dividerColor};
      border-radius: 12px;
      padding: 3px;
      p {
        width: 50%;
        font-size: 12px;
        color: #6a6991;
        line-height: 28px;
        text-align: center;
        &:hover {
          color: ${({ theme }) => theme.colors.text};
        }
        &.on {
          background-color: ${({ theme }) => theme.colors.secondary};
          border-radius: 8px;
          color: ${({ theme }) => theme.colors.text};
        }
      }
    }
  }
  table {
    width: 100%;
    thead,
    tbody {
      td {
        vertical-align: middle;
        ${({ theme }) => theme.mediaQueries.md} {
          &:nth-child(1) {
            padding-left: 16px;
          }
          &:last-child {
            padding-right: 16px;
          }
        }
      }
    }
    thead {
      display: none;
      font-size: 12px;
      color: #6a6991;
      td {
        padding-bottom: 16px;
        &:last-child {
          text-align: right;
        }
      }
      ${({ theme }) => theme.mediaQueries.md} {
        display: table-header-group;
      }
    }
    tbody {
      tr {
        ${({ theme }) => theme.mediaQueries.md} {
          border-bottom: 1px solid ${({ theme }) => theme.colors.dividerColor};
          transition: all 0.3s ease;
          cursor: pointer;
          ${({ theme }) => theme.mediaQueries.md} {
            &:hover {
              background-color: ${({ theme }) => theme.colors.background};
            }
          }
          td {
            padding-top: 30px;
            padding-bottom: 30px;
            &:last-child {
              font-size: 14px;
              color: #6a6991;
              line-height: 20px;
              text-align: end;
            }
          }
          &:hover {
            // background-color:${({ theme }) => theme.colors.background};
          }
        }
        .tx_list {
          // background: ${({ theme }) => theme.colors.background02};
          // border: 1px solid #2E2D50;
          // border-radius: 12px;
          // height: 40px;
          text-aign: right;
          font-size: 14px;
          color: #6a6991;
          // padding: 10px;
          display: inline-block;
          vertical-align: middle;
          // margin-top: 20px;
          ${({ theme }) => theme.mediaQueries.md} {
            // padding: 10px 20px;
            margin-top: 0;
            display: block;
          }
          a,
          p,
          svg {
            display: inline-block;
            vertical-align: middle;
          }
          a {
            color: #cc64f2;
            path {
              stroke: #cc64f2;
            }
          }
          svg {
            width: 18px;
            margin-top: -2px;
            margin-left: 4px;
          }
          .line_ {
            width: 1px;
            height: 20px;
            background: ${({ theme }) => theme.colors.borderColor};
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            margin: 0 10px;
            ${({ theme }) => theme.mediaQueries.md} {
              margin: 0 20px;
            }
          }
        }
        .flex {
          display: flex;
          align-items: center;
          justify-content: start;
          h3 {
            font-size: 15px;
            font-weight: 600;
            line-height: 20px;
          }
          h4 {
            font-size: 14px;
            font-weight: 600;
          }
          p {
            font-size: 12px;
            color: #6a6991;
          }
          &.bridge {
            img {
              width: 24px;
              height: 24px;
            }
          }
          &.card {
            height: 28px;
            width: 110px;
            justify-content: center;
            border-radius: 4px;
            ${({ theme }) => theme.mediaQueries.md} {
              padding: 0;
              width: 106px;
              height: 26px;
            }
            svg {
              margin-right: 8px;
            }
            p {
              font-size: 12px;
            }
          }
          &.card_green {
            border: 1px solid ${({ theme }) => theme.colors.green};
            // width: 100px;
            // ${({ theme }) => theme.mediaQueries.md} {
            //   width: 124px;
            // }
            svg {
              fill: ${({ theme }) => theme.colors.green};
              margin-right: 8px;
            }
            p {
              font-size: 12px;
              color: ${({ theme }) => theme.colors.green};
            }
          }
          &.card_grey {
            border: 1px solid #6a6991;
            .grey_err_path {
              path:first-child {
                fill: #6a6991;
              }
              path {
                fill: #01010e;
              }
            }
            svg {
              fill: ${({ theme }) => theme.colors.green};
              .one_path {
                fill: #6a6991;
              }
              .two_path {
                fill: ${({ theme }) => theme.colors.background};
              }
            }
            p {
              color: #6a6991;
            }
          }
        }
        img {
          width: 28px;
          height: 28px;
          margin-right: 8px;
          border-radius: 50%;
        }
      }
    }
  }
  .card_tr {
    display: block;
    background: ${({ theme }) => theme.colors.background};
    border-radius: 12px;
    margin-bottom: 12px;
    .card_top {
      padding: 16px 16px 20px;
      justify-content: start;
      align-items: start;
      ${({ theme }) => theme.mediaQueries.sm} {
        justify-content: space-between;
      }
      & > svg {
        width: 48px;
        padding: 10px;
        ${({ theme }) => theme.mediaQueries.md} {
          width: 88px;
          padding: 10px 30px;
        }
      }
      div {
        img {
          margin-bottom: 12px;
        }
        h3 {
          font-size: 15px;
          font-weight: 600;
        }
        p {
          font-weight: 500;
          font-size: 12px;
          color: #6a6991;
        }
      }
    }
    .card_bottom {
      padding: 14px 16px;
      background: ${({ theme }) => theme.colors.secondary};
      border-radius: 12px;
      justify-content: space-between;
      align-items: center;
    }
    .card_top,
    .card_bottom {
      display: flex;
    }
  }
`;

export const ModalStyled = styled(Modal)`
  padding-top: 10px;
  .type div.type_inner_big_top .type_inner_big_top_bg {
    background-color: ${({ theme }) => theme.colors.background02};
  }
  .copy_btn {
    div {
      margin-top: 10px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: end;
      font-size: 12px;
      cursor: pointer;
      transition: all 0.3s ease;
      opacity: 0.9;
      color: ${({ theme }) => theme.colors.primary};
      &:hover {
        opacity: 1;
      }
    }
    svg {
      fill: ${({ theme }) => theme.colors.primary};
      margin-right: 6px;
    }
  }
  .svg {
    display: block;
    margin: 0 auto;
    width: 60px;
    fill: ${({ theme }) => theme.colors.green};
    .one_path {
      fill: ${({ theme }) => theme.colors.green};
    }
    &.error {
      path:first-child {
        fill: #6a6991;
      }
      path {
        fill: #01010e;
      }
    }
    .two_path {
      fill: ${({ theme }) => theme.colors.text};
    }
    ${({ theme }) => theme.mediaQueries.md} {
      width: 80px;
    }
  }
  .title {
    text-align: center;
    font-size: 20px;
    padding: 20px 0 30px;
    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 30px;
      padding: 30px 0;
    }
  }
  div.line {
    margin: 0 auto;
    background: ${({ theme }) => theme.colors.background02};
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
    box-shadow: ${({ theme }) => (theme.isDark ? '0 10px 20px 5px rgba(0, 0, 0, 0.03)' : 'none')};
    border-radius: 12px;
    // width: 660px;
    padding: 20px 12px;
    text-align: center;
    ${({ theme }) => theme.mediaQueries.md} {
      // max-width: 660px;
      width: 660px;
      // margin: 0 10px;
    }
    .type div.type_inner_big_top .type_inner_big_top_bg {
      background-color: ${({ theme }) => theme.colors.background02};
    }
  }
  .tx_list {
    background: ${({ theme }) => theme.colors.background02};
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
    border-radius: 12px;
    height: 40px;
    font-size: 14px;
    color: #6a6991;
    padding: 10px;
    display: inline-block;
    margin-top: 20px;
    ${({ theme }) => theme.mediaQueries.md} {
      padding: 10px 20px;
      margin-top: 0;
      display: block;
    }
    a,
    p,
    svg {
      display: inline-block;
      vertical-align: middle;
    }
    a {
      color: #cc64f2;
      path {
        stroke: #cc64f2;
      }
    }
    svg {
      width: 18px;
      margin-top: -2px;
      margin-left: 4px;
    }
    .line_ {
      width: 1px;
      height: 20px;
      background: ${({ theme }) => theme.colors.borderColor};
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      margin: 0 10px;
      ${({ theme }) => theme.mediaQueries.md} {
        margin: 0 20px;
      }
    }
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;
    ${({ theme }) => theme.mediaQueries.md} {
      justify-content: space-between;
      margin-bottom: 40px;
    }
    a,
    p,
    svg {
      display: inline-block;
      vertical-align: middle;
    }

    .detail {
      font-size: 12px;
      color: #6a6991;
      p {
        background: ${({ theme }) => theme.colors.secondary};
        box-shadow: ${({ theme }) => (theme.isDark ? '0 10px 20px 5px rgba(0, 0, 0, 0.03)' : 'none')};
        border-radius: 12px;
        margin-left: 6px;
        margin-right: 6px;
        height: 36px;
        line-height: 36px;
        padding: 0 12px;
        ${({ theme }) => theme.mediaQueries.md} {
          margin-right: 0;
          margin-left: 12px;
        }
      }
    }
  }
  .grey_text {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    line-height: 20px;
    font-size: 12px;
    color: #6a6991;
    ${({ theme }) => theme.mediaQueries.md} {
      width: 50%;
      max-width: 300px;
    }
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    a {
      width: 48%;
      font-weight: 700;
      height: 46px;
      font-size: 16px;
      ${({ theme }) => theme.mediaQueries.md} {
        height: 60px;
        font-size: 18px;
      }
      &:first-child {
        background: transparent;
      }
    }
  }
`;
