import { CHAINID } from './chain_config';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  masterChef: {
    [CHAINID.BSC_TESTNET]: '0x7DE7fF5D0290695c7f8a4531ff77FFCC8461C29e',
    [CHAINID.BSC_MAINNET]: '0x81b71D0bC2De38e37978E6701C342d0b7AA67D59',
    [CHAINID.ASTR_TESTNET]: '0xc5b016c5597D298Fe9eD22922CE290A048aA5B75',
    [CHAINID.ASTR_MAINNET]: '0xc5b016c5597D298Fe9eD22922CE290A048aA5B75',
    [CHAINID.SDN_TESTNET]: '0x293A7824582C56B0842535f94F6E3841888168C8',
    [CHAINID.SDN_MAINNET]: '0x293A7824582C56B0842535f94F6E3841888168C8',
  },
  multiCall: {
    [CHAINID.ETH_MAINNET]: '0x5e227AD1969Ea493B43F840cfF78d08a6fc17796',
    [CHAINID.BSC_MAINNET]: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    [CHAINID.BSC_TESTNET]: '0xB44A8AEb4805a5404a8d20A1294a61C95Ae6F256',
    [CHAINID.ASTR_MAINNET]: '0x861aa52B81A16E146E1f8D39B6C8BdC072d8348d',
    [CHAINID.ASTR_TESTNET]: '0xB44A8AEb4805a5404a8d20A1294a61C95Ae6F256',
    [CHAINID.SDN_TESTNET]: '0xa5e48a6E56e164907263e901B98D9b11CCB46C47',
    [CHAINID.SDN_MAINNET]: '0xa5e48a6E56e164907263e901B98D9b11CCB46C47',
    [CHAINID.ARBITRUM_MAINNET]: '0xca11bde05977b3631167028862be2a173976ca11',
    [CHAINID.OPTIMISTIC_MAINNET]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  farmAuction: {
    [CHAINID.BSC_MAINNET]: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    [CHAINID.ASTR_TESTNET]: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    [CHAINID.ASTR_MAINNET]: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    [CHAINID.SDN_TESTNET]: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    [CHAINID.SDN_MAINNET]: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    [CHAINID.BSC_TESTNET]: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
};
