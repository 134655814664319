import BigNumber from 'bignumber.js';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'state';
import { usePrice } from 'state/price/hooks';
import { VaultState, State, VaultMatadateType } from 'state/types';
import { BIG_ZERO } from 'utils/bigNumber';
import {
  fetchMultiVaultFarmUserDataAsync,
  fetchTradeConfigAsync,
  fetchVaultBaseConfigAsync,
  fetchVaultMultiTokenAsync,
  // fetchVaultFarmUserDataAsync,
  // fetchVaultMultiTokenAsync,
  fetchVaultMultiTokenBalanceAsync,
  fetchVaultsPublicDataAsync,
} from './reducer';
import { getVaultDataByChosedData } from './utils';
import { CHAINID } from 'config/constants/chain_config';
import { useSafeContractState } from 'state/safeContract/hooks';
export const useMultiToken = () => {
  const dispatch = useAppDispatch();
  const { fetchVaultMultiTokenAsyncLoading } = useVault();
  useEffect(() => {
    if (dispatch) {
      //// console.log({ fetchVaultMultiTokenAsyncLoading });
      // if (fetchVaultMultiTokenAsyncLoading) {
      //   return;
      // }
      //// console.log(23233);
      dispatch(fetchVaultMultiTokenAsync());
      dispatch(fetchTradeConfigAsync());
    }
  }, [dispatch, fetchVaultMultiTokenAsyncLoading]);
};
export const usePollTokenBalance = ({ multiTokenConfig, account }) => {
  const { safeAddress } = useSafeContractState();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (dispatch && account && multiTokenConfig) {
      dispatch(
        fetchVaultMultiTokenBalanceAsync({
          account,
          safeAddress,
          multiTokenConfig,
        }),
      );
    }
  }, [dispatch, account, safeAddress, multiTokenConfig]);
};

export const usePollVaultData = () => {
  const dispatch = useAppDispatch();
  const { priceVsBusdMapMulti } = usePrice();
  const { chosedData: vaults, vaultsConfig, chosedChainAndDApp } = useVault();
  useEffect(() => {
    if (!vaultsConfig) {
      dispatch(fetchVaultBaseConfigAsync());
      return;
    }
    let _vaults: VaultMatadateType = vaults;
    if (dispatch && priceVsBusdMapMulti && Object.keys(priceVsBusdMapMulti).length) {
      if (!vaults) {
        _vaults = getVaultDataByChosedData({
          chosedChainAndDApp,
          allVault: vaultsConfig,
          init: true,
        });
      }
      dispatch(
        fetchVaultsPublicDataAsync({
          priceVsBusdMapMulti,
          vaultsData: _vaults,
        }),
      );
    }
    // eslint-disable-next-line
  }, [dispatch, priceVsBusdMapMulti, Boolean(vaultsConfig)]);
};
export const useVaultUserDataMulti = (vaults: VaultMatadateType, account: string) => {
  const dispatch = useAppDispatch();
  const { safeAddress } = useSafeContractState();
  const [apyChanged, setApyChanged] = useState(false);
  useMemo(() => {
    if (apyChanged) {
      return;
    }
    if (Object.values(vaults ?? {})[0]?.dapp[0]?.contract[0]?.vault?.total_apy) {
      setApyChanged(true);
    }
    setApyChanged(false);
    // eslint-disable-next-line
  }, [apyChanged, Boolean(vaults)]);
  useEffect(() => {
    if (!dispatch || !account || !vaults || !safeAddress) {
      return;
    }
    let interval = null;
    dispatch(fetchMultiVaultFarmUserDataAsync({ account, vaults, safeAddress }));
    interval = setInterval(async () => {
      try {
        dispatch(fetchMultiVaultFarmUserDataAsync({ account, vaults, safeAddress }));
        // (vaults ?? {})[CHAINID.ARBITRUM_MAINNET]?.dapp[0]?.contract[0]?.vault?.total_apy,
        // vaults?.arbitrum?.dapp[0]?.contract[0]?.vault?.total_apy,
      } catch (e) {
        if (interval) {
          clearInterval(interval);
        }
      }
    }, 40000);

    return () => (interval ? clearInterval(interval) : null);
    // eslint-disable-next-line
  }, [
    dispatch,
    account,
    apyChanged,
    // vaults[CHAINID.ARBITRUM_MAINNET]?.dapp[0]?.contract[0]?.vault?.total_apy,
    // vaults?.[42161]?.dapp[0]?.contract[0]?.vault?.total_apy,
    safeAddress,
  ]);
};
// add safe contract
// export const useVaultUserData = (vaults: VaultMatadateType) => {
//   const { account } = useWeb3React();

//   const dispatch = useAppDispatch();
//   const { safeAddress } = useSafeContractState();

//   useEffect(() => {
//     if (!safeAddress) return;
//     dispatch(changeLoading());
//     dispatch(fetchVaultFarmUserDataAsync({ account, vaults, safeAddress }));
//   }, [dispatch, account, vaults, safeAddress]);
//   useEffect(() => {
//     dispatch(changeVaultLoading());
//   }, [dispatch]);
// };
export const useVault = (): VaultState => {
  const vault = useSelector((state: State) => {
    return state.vault;
  });

  return vault;
};

export const useVaultAllTotal = () => {
  const vault = useSelector((state: State) => state.vault);
  return vault.tvlTotal;
};
export const useVaultFarmUser = (
  account: string,
  vaultAccount: string,
  chainkeyFromSource: CHAINID,
  dappFromSource: string,
) => {
  try {
    const vault = useSelector((state: State) =>
      state.vault.chosedData[chainkeyFromSource].dapp[dappFromSource].contract.find(
        (f) => f.contractAddress.toLowerCase() === vaultAccount.toLowerCase(),
      ),
    );
    const { farm } = vault;

    const userData = farm.userData ?? {};
    const _userDataKey = `${account}`;
    const _userData = userData[_userDataKey] ?? {
      account: '',
      allowance: '0',
      stakingTokenBalance: '0',
      // stakedBalance: '0',
      // pendingReward: '0',
      avaultaddressBalance: '0',
    };
    return {
      allowance: new BigNumber(_userData.allowance),
      stakingTokenBalance: new BigNumber(_userData.stakingTokenBalance),
      // stakedBalance: new BigNumber(_userData.stakedBalance),
      // pendingReward: new BigNumber(_userData.pendingReward),
      avaultaddressBalance: new BigNumber(_userData.avaultaddressBalance),
    };
  } catch (e) {
    return {
      allowance: BIG_ZERO,
      stakingTokenBalance: BIG_ZERO,
      // stakedBalance: BIG_ZERO,
      // pendingReward: BIG_ZERO,
      avaultaddressBalance: BIG_ZERO,
    };
  }
};
export const useVaultData = () => {
  const vaultData = useSelector((state: State) => state.vault.vaultsConfig);
  return vaultData;
};
