export type EncodeAndDecodeOptions<T = any> = {
  encode: (value: T) => string;
  decode: (value: string | null | undefined) => T | null;
};
type LooseAutocomplete<T> = {
  [K in keyof T]: T[K];
} & {
  [K: string]: any;
};
export class ParamsUtils {
  public static encode(obj?: Record<string, string | string[] | number | boolean> | any): string {
    if (obj === null || obj === undefined) {
      return '';
    }
    const parts: string[] = [];
    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      if (typeof value !== 'undefined' && value !== null && value !== '') {
        if (value instanceof Array) {
          value.forEach((vv) => {
            parts.push(`${key}=${String(vv)}`);
          });
        } else {
          parts.push(`${key}=${String(value)}`);
        }
      }
    });
    parts.sort();
    return parts.join('&');
  }
  public static decode<T>(searchParams?: URLSearchParams): LooseAutocomplete<T> {
    const params = Array.from(searchParams?.keys?.() || []);
    const values = Array.from(searchParams?.values?.() || []);
    const obj: any = {};
    params.map((key, index) => {
      const value = values[index];
      if (key in obj) {
        const objValue = obj[key];
        if (objValue instanceof Array) {
          obj[key] = [...objValue, value];
        } else {
          obj[key] = [objValue, value];
        }
        console.log(obj);
      } else {
        obj[key] = value;
      }
      return undefined;
    });
    return obj as LooseAutocomplete<T>;
    // const uniqueKeys = Array.from(
    //   new Set(Array.from(searchParams?.keys?.() || []))
    // );
    // console.log(Array.from(searchParams?.keys?.() || []), { uniqueKeys });
    // return Object.fromEntries(
    //   uniqueKeys.map((key) => {
    //     let fnToCall: EncodeAndDecodeOptions["decode"] = (value) => value;
    //     const optionsKey = (options as any)?.[key];
    //     if (
    //       typeof optionsKey !== "boolean" &&
    //       typeof optionsKey?.decode === "function"
    //     ) {
    //       fnToCall = optionsKey.decode;
    //     }
    //     const value = searchParams?.get(key);
    //     console.log(111, value);
    //     return [key, fnToCall(value)];
    //   })
    // ) as unknown as LooseAutocomplete<T>;
  }
}
