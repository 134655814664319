import { useMatchBreakpoints, useModal } from '@my/ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BigItem from './components/BigItem';
import SmallItem from './components/SmallItem';
import ShowStatusModal from './ShowStatusModalV2';
import { VaultMultiTransactionListStyled } from './styled';
import PageLoader from 'components/Loader/PageLoader';
import { useWeb3React } from '@web3-react/core';
import { useGlobalState } from 'state/global/hooks';
import { ActionType, AvaultTask } from 'state/vaultHistory/types';
// import { useJumpPath } from 'hooks/useJumpPath';
// import { routePath } from 'config/constants/meta';

const VaultMultiTransactionList = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [tdIndex, setTdIndex] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  const { isXs, isSm, isMd } = useMatchBreakpoints();
  const { account } = useWeb3React();
  // const { chain } = useGlobalState();
  const { multiHistory, isMultiHistoryLoading } = useGlobalState();
  const showData = useMemo(() => {
    console.log({ multiHistory: multiHistory[9] });
    return multiHistory
      .filter((v) => {
        const subTasksLen = v.subTasks.length;
        const lastSubTasks = v.subTasks[subTasksLen - 1];
        const firstSubTasks = v.subTasks[0];
        const actionLen = firstSubTasks.actions.length;
        const lastAction = lastSubTasks.actions[actionLen - 1];
        const firstAction = firstSubTasks.actions[0];
        // console.log({ firstAction: firstAction.actionType });
        if (tabIndex === 0) {
          return firstAction.actionType === ActionType.DEPOSIT || lastAction.actionType === ActionType.DEPOSIT;
        } else {
          if (firstAction.actionType === ActionType.DEPOSIT || lastAction.actionType === ActionType.DEPOSIT) {
            return false;
          }
          return true;
        }
      })
      .sort((a, b) => b.createTime - a.createTime);
  }, [tabIndex, multiHistory]);
  const multiHistoryLen = useMemo(() => {
    if (multiHistory && multiHistory.length) {
      if (showData[0]) {
        return true;
      }
    }
    return false;
  }, [multiHistory, showData]);
  // useHistoryMultiGet();
  useEffect(() => {
    if ([isXs, isSm, isMd].some(Boolean)) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isXs, isSm, isMd]);
  const modeData = useMemo(() => {
    if (multiHistoryLen) {
      return showData[tdIndex];
    }
    return null;
  }, [showData, tdIndex, multiHistoryLen]);
  const [onPresetModal] = useModal(
    <ShowStatusModal collapsed={collapsed} modeData={modeData} account={account} />,
    true,
    true,
    'ShowStatusModal4444',
  );
  // const { jump } = useJumpPath(
  //   `${routePath.vault_transaction_list_detail.pathPre}/${multiHistoryLen ? multiHistory[tdIndex]?.fromtx : ''}`,
  // );

  const onPressModal = useCallback(
    (index: number, e) => {
      setTdIndex(index);
      onPresetModal();
      // jump(e);
    },
    [setTdIndex, onPresetModal],
  );
  console.log({ showData });
  return (
    <VaultMultiTransactionListStyled>
      <div className="tab_header">
        <h2>Transactions List</h2>
        <div className="tab">
          {['Deposit', 'Withdraw'].map((v, index: number) => (
            <p className={tabIndex === index ? 'on' : ''} key={v} onClick={() => setTabIndex(index)}>
              {v}
            </p>
          ))}
        </div>
      </div>
      <table>
        <thead>
          <tr>
            {['Source Token', 'Destination Token', 'Bridge', 'Status', 'Time'].map((v) => (
              <td key={v}>{v}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {!multiHistoryLen && !isMultiHistoryLoading ? (
            <tr>
              <td>NoDate</td>
            </tr>
          ) : (
            showData.map((item: AvaultTask, index: number) =>
              collapsed ? (
                <SmallItem key={index} onPressModal={onPressModal} index={index} item={item} />
              ) : (
                <BigItem key={index} onPressModal={onPressModal} index={index} item={item} />
              ),
            )
          )}
        </tbody>
      </table>
      {isMultiHistoryLoading ? <PageLoader /> : null}
    </VaultMultiTransactionListStyled>
  );
};

export default VaultMultiTransactionList;
