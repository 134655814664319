import { CHAINID } from 'config/constants/chain_config';
export enum IDAppName {
  AAVE = 'AAVE',
  STARGATE = 'Stargate',
  arthswap = 'arthswap',
}

export enum IABIType {
  AVaultPCS = 'AVaultPCS',
  AVaultForStarlay = 'AVaultForStarlay',
  AVaultForAAVE = 'AVaultForAAVE',
  AVaultForStargate = 'AVaultForStargate',
}

// export const VaultType = ['All', 'Event', 'Arthswap', 'Starlay'];
export const VaultType = [
  {
    name: 'All',
    key: 0,
  },
  {
    name: 'Compound',
    key: 4,
  },
  {
    name: 'Stake',
    key: 5,
  },
  {
    name: 'Stablecoin',
    key: 3,
  },
  {
    name: 'Single',
    key: 1,
  },
  {
    name: 'Lp token',
    key: 2,
  },
];
export enum IVaultType {
  all = 0,
  single = 1,
  lp_token = 2,
  stablecoin = 3,
  compound = 4,
  stake = 5,
}
export enum IEventType {
  normal = 0,
  active = 1,
  addNew = 2,
}
export interface IVaultConfigItem {
  vaulttype: IVaultType[]; // 1 stablecoin 2 single 3 lp_token
  eventtype: IEventType; // 0 normal  1 active   2 addNew
  chainkey: CHAINID;
  dappname: IDAppName;
  abitype: IABIType;
  contractAddress: string;
  farm?: IVaultFarm;
  vault?: IVaultComp;
}
export interface IVaultFarm {
  // abi
  pid: number;
  lpSymbol: string;
  lpAddresses: string;
  token: string;
  quoteToken: string;
  tokenDecimals: number;
  quoteTokenDecimals: number;
  userData?: Record<string, IVaultUserData>;
}
export interface IRewardtoken {
  symbol: string;
  address?: string;
  decimals?: number;
  logoURI?: string;
  fnname?: string;
}
export interface IVaultStake {
  chainId: number;
  routerEthAddress?: string;
  routerAddress: string;
  pooladdress: string;
  poolid: number;
  lpstaking: string;
  pid: number;
  bridge: string;
  stakesymbol: string;
  stakelogoURI: string;
  wantaddress: string;
  wantaddresssymbol: string;
  wantaddressdecimals: number;
  name: string;
  earnedaddresssymbol: IRewardtoken[];
  // decimals: number;
  amountLPtoLDFromNumber: string;
  amountLPtoLD: string;
  tvl?: string;
  isbalance?: boolean;
  total_apr?: string;
  total_apy?: string;
  base_apr?: string;
  base_apy?: string;
  reward_apr?: string;
  reward_apy?: string;
}
export interface IVaultUserData {
  index?: number;
  vaultAccount?: string;
  account: string;
  allowance: string;
  stakingTokenBalance: string;
  // stakedBalance: string;
  // pendingReward: string;
  avaultaddressBalance: string;
}
export interface IVaultComp {
  // abi
  wantaddresssymbol: string;
  vaultsymbol: string;
  vaultlogoURI: string;
  name: string;
  avaaddress: string;
  // dappname: IDAppName;
  wantaddress: string;
  wantaddressdecimals?: number;
  wantLockedTotal?: string;
  earnedaddress: string;
  earnedaddresssymbol: IRewardtoken[];
  scale?: string;
  totalSupply?: string;
  decimals: number;
  isbalance?: boolean;
  tvl?: string;
  // calculate
  lpToCLpRate?: string;
  masterChef?: string;
  token0address?: string; // if single Token  token0 =  single Token
  token1address?: string;
  total_apr?: string;
  total_apy?: string;
  base_apr?: string;
  base_apy?: string;
  reward_apr?: string;
  reward_apy?: string;
}
export interface IVault extends IVaultConfigItem {
  vault?: IVaultComp;
  farm?: IVaultFarm;
  stake?: IVaultStake;
  // isLoading: boolean;
}
