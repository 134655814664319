import { useEffect, useState } from 'react';
import LIFI, { Orders, QuoteRequest, Route, RoutesRequest, Step } from '@lifi/sdk';
import { CHAINID } from 'config/constants/chain_config';
import { SafeAddress } from 'state/types';
// import { nativeQuoteResultsObj, routes } from './lifi/routes';
import BigNumber from 'bignumber.js';
import { BIG_TEN } from 'utils/bigNumber';
import { ParamsUtils } from 'utils/paramsUtils';
import { useAppDispatch } from 'state';
import { changeStateValue } from 'state/vault';
const lifiConfig = {
  apiUrl: 'https://staging.li.quest/v1/',
};
export const proLink = 'https://li.quest/v1/';
const sdk = new LIFI(lifiConfig);
export interface ILifiRoutes extends ILifiParamsRoutesCom {
  fromChainId: CHAINID;
  toChainId: CHAINID;
  fromtokendecimals: number;
  safeAddress: SafeAddress;
  account: string;
  fromsymbol: string;
  tosymbol: string;
  balance: string;
}
interface ILifiParamsRoutes extends ILifiParamsRoutesCom {
  fromChainId: number;
  toChainId: number;
}
interface ILifiParamsRoutesCom {
  fromAmount: string;
  fromTokenAddress: string;
  toTokenAddress: string;
}
export interface IRouteList extends Route {
  stepsData?: Step[];
}
export const bridgeList = [
  'across',
  'arbitrum',
  'avalanche',
  'cbridge',
  'connext',
  'hop',
  'hyphen',
  'optimism',
  'polygon',
  'stargate',
  'gnosis',
  'omni',
];
const routeOptions = {
  slippage: 0.005,
  referrer: '',
  integrator: 'avault.network',
  bridges: {
    allow: bridgeList,
  },
  exchanges: {
    allow: [
      'dodo',
      'paraswap',
      '1inch',
      'openocean',
      '0x',
      'superfluid',
      'uniswap',
      'quickswap',
      'pancakeswap',
      'honeyswap',
      'swapr',
      'spookyswap',
      'spiritswap',
      'soulswap',
      'solarbeam',
      'jswap',
      'okcswap',
      'cronaswap',
      'stellaswap',
      'beamswap',
      'voltage',
      'ubeswap',
      'sushiswap',
      'trisolaris',
      'wagyuswap',
      'pangolin',
    ],
  },
  order: Orders[0],
};
export const useLifiRoutes = ({
  fromChainId,
  fromAmount,
  fromTokenAddress,
  toChainId,
  toTokenAddress,
  fromtokendecimals,
  account,
  safeAddress,
  fromsymbol,
  tosymbol,
  balance,
}: ILifiRoutes) => {
  const [routeList, setRouteList] = useState<Record<string, IRouteList[]>>();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (fromChainId && fromAmount && fromTokenAddress && toChainId && toTokenAddress && account && safeAddress) {
      if (new BigNumber(fromAmount).gt(balance) || Number(fromAmount) === 0) {
        dispatch(changeStateValue({ key: 'routeListLoading', value: false }));
      } else {
        dispatch(changeStateValue({ key: 'routeListLoading', value: true }));
        // setRouteList([]);

        // const fromchainObj = getWalletConfig(fromChainId);
        // const tochainObj = getWalletConfig(toChainId);
        // // setRouteList([]);
        // const amountIn = new BigNumber(`${fromAmount}`).times(BIG_TEN.pow(fromtokendecimals || 18)).toFixed(0);
        //   setDirectItem({
        //     collapsed: collapsed,
        //     startvalue: amountIn,
        //     fromDecimals: fromtokendecimals,
        //     fromtokensymbol: fromsymbol,
        //     fromChainObj: fromchainObj,
        //     fromdappname: fromdappname,
        //     label: label,
        //     endvalue: amountIn,
        //     toDecimals: totokendecimals,
        //     totokensymbol: tosymbol,
        //     toChainObj: tochainObj,
        //     todappname: todappname,
        //   });
        //   dispatch(changeStateValue({ key: 'routeListLoading', value: false }));
        // } else {
        // if (timerRef.current) {
        //   return;
        // }
        // setDirectItem(null);

        // timerRef.current = setTimeout(async () => {
        (async () => {
          // 1. 一次交易的时候
          // 1.1 uo  需要和lifi合约交互吗   /quote
          // 2. 二次交易的时候
          // 2.1 跨链的时候
          //  uo  需要和lifi合约交互吗   /quote
          // if (routeListLoading) return;
          const amountIn = new BigNumber(`${fromAmount}`).times(BIG_TEN.pow(fromtokendecimals || 18)).toFixed(0);
          if (amountIn !== '0') {
            const fromObj = {
              fromChainId: Number(fromChainId),
              toChainId: Number(toChainId),
              fromAmount: amountIn,
              toAmount: amountIn,
              fromToken: {
                symbol: fromsymbol,
                address: fromTokenAddress,
                decimals: fromtokendecimals,
              },
              toToken: {
                symbol: tosymbol,
                address: fromTokenAddress,
                decimals: fromtokendecimals,
              },
              steps: [],
            } as IRouteList;
            if (fromChainId === toChainId && fromTokenAddress === toTokenAddress) {
              setRouteList({
                [fromAmount]: [fromObj],
              });
            } else {
              const nativeRoutes = await getLifiRoutes({
                fromChainId: Number(fromChainId),
                fromAmount: amountIn,
                fromTokenAddress,
                toChainId: Number(toChainId),
                toTokenAddress,
              });

              setRouteList({
                [fromAmount]: nativeRoutes,
              });
            }
            // todo
            // const nativeRoutes = routes;

            dispatch(changeStateValue({ key: 'routeListLoading', value: false }));
            // if (nativeRoutes && nativeRoutes.length) {
            //   const _routeList = [];
            //   const avatar = safeAddress[account][toChainId];
            //   let toAccountAddress = account;
            //   const nativeQuoteResults = await Promise.all(
            //     nativeRoutes.map((v: Route) => {
            //       return Promise.all(
            //         v.steps.map((vv: Step, index) => {
            //           if (index === 1) {
            //             toAccountAddress = fromChainId === toChainId ? account : avatar;
            //           }
            //           return getLifiQuote({
            //             fromChain: vv.action.fromChainId,
            //             fromToken: vv.action.fromToken.address,
            //             fromAddress: account,
            //             fromAmount: vv.action.fromAmount,
            //             toToken: vv.action.toToken.address,
            //             toChain: vv.action.toChainId,
            //             toAddress: toAccountAddress,
            //             slippage: vv.action.slippage,
            //           });
            //         }),
            //       );
            //     }),
            //   );
            //   // const nativeQuoteResults = nativeQuoteResultsObj;
            //   // const nativeQuoteResults = [quote00, quote00, quote00, quote00];
            //   for (let i = 0; i < nativeRoutes.length; i++) {
            //     const _route = nativeRoutes[i];
            //     const _quoteList = nativeQuoteResults[i];
            //     _routeList.push({
            //       ..._route,
            //       stepsData: _quoteList,
            //     });
            //   }
            //   dispatch(changeStateValue({ key: 'routeListLoading', value: false }));
            //   setRouteList(_routeList);
            // } else {
            //   if (routeList.length) {
            //     dispatch(changeStateValue({ key: 'routeListLoading', value: false }));
            //     //     setRouteList([]);
            //   }
            // }
          }
          // get quotes
          // timerRef.current = null;
          // }, 1000);
        })();
        // }
      }
    } else {
      setRouteList({});
      dispatch(changeStateValue({ key: 'routeListLoading', value: false }));
    }
    // return timerRef.current ? clearTimeout(timerRef.current) : null;
    // eslint-disable-next-line
  }, [fromAmount]);
  return { routeList };
};
export const getLifiRoutes = async ({
  fromChainId,
  fromAmount,
  fromTokenAddress,
  toChainId,
  toTokenAddress,
}: ILifiParamsRoutes) => {
  const routesRequest: RoutesRequest = {
    fromChainId: fromChainId,
    fromAmount: fromAmount, // 1USDT
    fromTokenAddress: fromTokenAddress,
    toChainId: toChainId,
    toTokenAddress: toTokenAddress,
    options: routeOptions,
  };
  const result = await sdk.getRoutes(routesRequest);
  const routes: Route[] = result.routes;
  return routes;
};
// const getLifiQuoteAll = async (stepItem: Step[],account:string,avatar:str fromChainId:CHAINID,toChainId:CHAINID,) => {
//           let toAccountAddress = account;
//           const result =Promise.all(
//     stepItem.map((vv: Step, index:number) => {
//       if (index === 1) {
//         toAccountAddress = fromChainId === toChainId ? account : avatar;
//       }
//       return getLifiQuote({
//         fromChain: vv.action.fromChainId,
//         fromToken: vv.action.fromToken.address,
//         fromAddress: account,
//         fromAmount: vv.action.fromAmount,
//         toToken: vv.action.toToken.address,
//         toChain: vv.action.toChainId,
//         toAddress: toAccountAddress,
//         slippage: vv.action.slippage,
//       });
//      }))
//   return result;
// };
export const getLifiQuote = async (params: QuoteRequest) => {
  const result = await getQuote(params);
  return result;
};

const getQuote = async (params: QuoteRequest) => {
  try {
    const queryString = ParamsUtils.encode({
      ...params,
      integrator: 'avault.network',
    });
    const response = await fetch(proLink + 'quote?' + queryString);
    const data = await response.json();
    if (data.errors) {
      return null;
    }
    return data;
  } catch (error) {
    console.error('Unable to fetch quote:', error);
    throw Error(`Unable to fetch quote: ${error}`);
  }
};
