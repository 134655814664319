import { useTooltip } from '@my/ui';
import ProcessIcon from 'components/ProcessIcon/ProcessIcon';
import { routePath } from 'config/constants/meta';
import { useJumpPath } from 'hooks/useJumpPath';
import { memo, useEffect, useRef } from 'react';
import { IProcessList } from 'state/global/types';
import { useThemeContext } from 'state/user/hook';
import ListIcon from '../imgs/ListIcon';
import { ProcessListStyled, ProcessStyled } from './ProcessStyled';

const Process = ({ processList, account }: { processList: IProcessList[]; account: string }) => {
  const theme = useThemeContext();
  // console.log({ safeAddress, account, multiToken, multiTokenConfig, collapsed });
  const setMoreTooltipVisible = useRef<React.Dispatch<React.SetStateAction<boolean>>>();
  const {
    targetRef: ProcessTargetRef,
    tooltip: ProcessTooltip,
    tooltipVisible: ProcessTooltipVisible,
    setTooltipVisible,
  } = useTooltip(<ProcessList processList={processList} setMoreTooltipVisible={setMoreTooltipVisible} />, {
    trigger: 'click',
    tootipStyle: {
      // position: 'fixed',
      backgroundColor: 'transparent',
      background: theme.isDark ? theme.colors.background02 : theme.colors.background,
      border: '1px solid #2E2D50',
      boxShadow: '2px 12px 16px 0 rgba(0, 0, 0, 0.3)',
      padding: '20px',
      minWidth: '360px',
      marginTop: '7px',
    },
    // placement: 'bottom-end',
    hideArrow: false,
    arrowBackground: theme.isDark ? theme.colors.background02 : theme.colors.background,
  });
  useEffect(() => {
    setMoreTooltipVisible.current = setTooltipVisible;
  }, [setTooltipVisible]);
  if (!account) {
    return null;
  }
  return (
    <>
      {ProcessTooltipVisible && ProcessTooltip}
      <ProcessStyled ref={ProcessTargetRef}>
        <ProcessIcon className={`loading ${!processList || !processList.length ? 'stop' : ''}`} />
      </ProcessStyled>
    </>
  );
};
export const ProcessList = ({
  processList,
  setMoreTooltipVisible,
}: {
  processList: IProcessList[];
  setMoreTooltipVisible: React.MutableRefObject<React.Dispatch<React.SetStateAction<boolean>>>;
}) => {
  return (
    <ProcessListStyled>
      {!processList || !processList.length ? <NoProcess /> : null}
      {processList && processList.length
        ? processList.map((v, index) => {
            return <ProcessListCompItem key={index} item={v} setMoreTooltipVisible={setMoreTooltipVisible} />;
          })
        : null}
    </ProcessListStyled>
  );
};
const NoProcess = () => {
  return (
    <div className="no_process">
      <ListIcon />
      <p>There is no task process</p>
    </div>
  );
};

const ProcessListCompItem = ({
  item,
  setMoreTooltipVisible,
}: {
  item: IProcessList;
  setMoreTooltipVisible: React.MutableRefObject<React.Dispatch<React.SetStateAction<boolean>>>;
}) => {
  const { jump } = useJumpPath(routePath.vault_transaction_list.path + '?fromtx=' + item.txHash, () => {
    setMoreTooltipVisible.current(false);
  });
  return (
    <div className="flex" onClick={jump}>
      <ProcessIcon className="loading-item" />
      <div className="fr">
        <h5>Waiting for {item.taskStatus.toLowerCase()}</h5>
        <p>Check Details &gt;&gt;</p>
      </div>
    </div>
  );
};
export default memo(Process);
