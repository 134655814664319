import { CHAINID } from 'config/constants/chain_config';
import { LiquidityBridgeTokens } from './LiquidityBridgeTokens';

// ## arbitrum
// ModuleEnabler deployed to: 0x7fF612bAE2CE449Ce7cE544dfdfe37ffF2605200
// module deployed to: 0x651BE2029a390bae194f3268D6fA3A034476e884
// router deployed to: 0xcd399c703ad32f6790c41d617D078b66DD0A4C02
// AVaultForAaveFactory deployed to: 0xBCe212EA0474F464488e963E793010205175f123
// aaETH deployed to: 0x603B5383d1F701C120D4A8E24F2DAccEF30b80b4
// aaUSDC deployed to: 0x99c192D657D3827A8BDe853aEcDa24cD41D53eb9
// AddressesProvider deployed to: 0xbEF7b01e1113B4946dAF6C1e8d01f3A9d4577197
// UOExecutorL2 deployed to: 0x5Af88505CF2cE57bb5e36816d7853A221F6Fc981
// LayerZeroUA deployed to: 0xA65e15Eff98BB57c15935948A0dCA190651F1EF8
// MultiSendCallOnly depoyed to: 0x40A2aCCbd92BCA938b02010E17A5b8929b49130D
// MultiSend deployed to: 0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761

// ## optimism
// ModuleEnabler deployed to: 0x7fF612bAE2CE449Ce7cE544dfdfe37ffF2605200
// module deployed to: 0xcd399c703ad32f6790c41d617D078b66DD0A4C02
// router deployed to: 0xB1543fA05fB09f6164A46F65656587a2d40ed35B
// AVaultForAaveFactory deployed to: 0xBCe212EA0474F464488e963E793010205175f123
// aaUSDC deployed to: 0x603B5383d1F701C120D4A8E24F2DAccEF30b80b4
// aaETH deployed to: 0x99c192d657d3827a8bde853aecda24cd41d53eb9
// AddressesProvider deployed to: 0x5Eca1457EF6531c6A0A4a9a4054789aCAe303dB6
// UOExecutorL2 deployed to: 0xB89AadF84f118D59D5770e6FEe9cF1b55244Ea8B
// LayerZeroUA deployed to: 0x5A6f79a1344dF47BF843281f5A458219B0e50457
// MultiSendCallOnly depoyed to: 0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B
// MultiSend deployed to: 0x998739BFdAAdde7C933B942a68053933098f9EDa

// ## bsc
// ModuleEnabler deployed to: 0xcBcbc868041a5C9872c9A0AECeB93724164dcd53
// module deployed to: 0xBCe212EA0474F464488e963E793010205175f123
// MultiSendCallOnly depoyed to: 0x40A2aCCbd92BCA938b02010E17A5b8929b49130D
// MultiSend deployed to: 0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761
// SmartDepositor deployed to: 0xBb6F40E8739cC5b89A247AA54C612D7E3cc9AD2a

// ## polygon
// ModuleEnabler deployed to: 0xcBcbc868041a5C9872c9A0AECeB93724164dcd53
// module deployed to: 0xBCe212EA0474F464488e963E793010205175f123
// MultiSendCallOnly depoyed to: 0x40A2aCCbd92BCA938b02010E17A5b8929b49130D
// MultiSend deployed to: 0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761

export const routerContractAddress = {
  [CHAINID.ARBITRUM_MAINNET]: '0xcd399c703ad32f6790c41d617D078b66DD0A4C02',
  [CHAINID.OPTIMISTIC_MAINNET]: '0xB1543fA05fB09f6164A46F65656587a2d40ed35B',
};
export const ModuleEnablerAddress = {
  [CHAINID.ARBITRUM_MAINNET]: '0x7fF612bAE2CE449Ce7cE544dfdfe37ffF2605200',
  [CHAINID.OPTIMISTIC_MAINNET]: '0x7fF612bAE2CE449Ce7cE544dfdfe37ffF2605200',
  [CHAINID.BSC_MAINNET]: '0xcBcbc868041a5C9872c9A0AECeB93724164dcd53',
  [CHAINID.POLYGON_MAINNET]: '0xcBcbc868041a5C9872c9A0AECeB93724164dcd53',
};

export const sigValidationModuleAddress = {
  [CHAINID.ARBITRUM_MAINNET]: '0x651BE2029a390bae194f3268D6fA3A034476e884',
  [CHAINID.OPTIMISTIC_MAINNET]: '0xcd399c703ad32f6790c41d617D078b66DD0A4C02',
  [CHAINID.BSC_MAINNET]: '0xBCe212EA0474F464488e963E793010205175f123',
  [CHAINID.POLYGON_MAINNET]: '0xBCe212EA0474F464488e963E793010205175f123',
};

export const uOExecutorL2Address = {
  [CHAINID.ARBITRUM_MAINNET]: '0x5Af88505CF2cE57bb5e36816d7853A221F6Fc981',
  [CHAINID.OPTIMISTIC_MAINNET]: '0xB89AadF84f118D59D5770e6FEe9cF1b55244Ea8B',
};

export const LayerZeroUAAddress = {
  [CHAINID.ARBITRUM_MAINNET]: '0xA65e15Eff98BB57c15935948A0dCA190651F1EF8',
  [CHAINID.OPTIMISTIC_MAINNET]: '0x5A6f79a1344dF47BF843281f5A458219B0e50457',
};
export const MultiSendCallOnly = {
  [CHAINID.ARBITRUM_MAINNET]: '0x40A2aCCbd92BCA938b02010E17A5b8929b49130D',
  [CHAINID.OPTIMISTIC_MAINNET]: '0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B',
  [CHAINID.BSC_MAINNET]: '0x40A2aCCbd92BCA938b02010E17A5b8929b49130D',
  [CHAINID.POLYGON_MAINNET]: '0x40A2aCCbd92BCA938b02010E17A5b8929b49130D',
};

export const MultiSend = {
  [CHAINID.ARBITRUM_MAINNET]: '0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761',
  [CHAINID.OPTIMISTIC_MAINNET]: '0x998739BFdAAdde7C933B942a68053933098f9EDa',
  [CHAINID.BSC_MAINNET]: '0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761',
  [CHAINID.POLYGON_MAINNET]: '0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761',
};
export const SmartDepositor = {
  [CHAINID.ARBITRUM_MAINNET]: '0xC9B203625Ae754F8fbA09C3d24343cef09F02eAA',
  [CHAINID.OPTIMISTIC_MAINNET]: '0xF9F7489A47Dd4C4815FF764B75114751AD339DC5',
  [CHAINID.BSC_MAINNET]: '0x38325f901a698aF88D855f061d0FEA70825856c5',
  [CHAINID.POLYGON_MAINNET]: '0x9A6080753a35dCd8e77102aE83A93170A831393e',
};

export const lzEndpointAddress = {
  [CHAINID.ARBITRUM_MAINNET]: '0x3c2269811836af69497e5f486a85d7316753cf62',
  [CHAINID.OPTIMISTIC_MAINNET]: '0x3c2269811836af69497e5f486a85d7316753cf62',
};

export const stargateRouterAddress = {
  // leave StargateConfig as '' if you want to deploy a router with deployments
  [CHAINID.ETH_MAINNET]: '0x8731d54E9D02c286767d56ac03e8037C07e01e98',
  [CHAINID.OPTIMISTIC_MAINNET]: '0xB0D502E938ed5f4df2E681fE6E419ff29631d62b',
  [CHAINID.BSC_MAINNET]: '0x4a364f8c717cAAD9A442737Eb7b8A55cc6cf18D8',
  [CHAINID.POLYGON_MAINNET]: '0x45A01E4e04F14f7A4a6702c74187c5F6222033cd',
  [CHAINID.FANTOM_MAINNET]: '0xAf5191B0De278C7286d6C7CC6ab6BB8A73bA2Cd6',
  [CHAINID.ARBITRUM_MAINNET]: '0x53Bf833A5d6c4ddA888F69c22C88C9f356a41614',
  [CHAINID.AVALANCH_MAINNET]: '0x45A01E4e04F14f7A4a6702c74187c5F6222033cd',
  // TESTNETS
  [CHAINID.ETH_RINKEBY]: '0x82A0F5F531F9ce0df1DF5619f74a0d3fA31FF561',
  [CHAINID.BSC_TESTNET]: '0xbB0f1be1E9CE9cB27EA5b0c3a85B7cc3381d8176',
  // [CHAINID.Fuji]: "0x13093E05Eb890dfA6DacecBdE51d24DabAb2Faa1",
  // [CHAINID.Mumbai]: "0x817436a076060D158204d955E5403b6Ed0A5fac0",
  [CHAINID.ARBITRUM_TESTNET]: '0x6701D9802aDF674E524053bd44AA83ef253efc41',
  [CHAINID.OPTIMISTIC_TESTNET]: '0xCC68641528B948642bDE1729805d6cf1DECB0B00',
  [CHAINID.FANTOM_TESTNET]: '0xa73b0a56B29aD790595763e71505FCa2c1abb77f',
};
export const stargateRouterETHAddress = {
  // leave StargateConfig as '' if you want to deploy a router with deployments
  [CHAINID.ETH_MAINNET]: '0x150f94B44927F078737562f0fcF3C95c01Cc2376',
  [CHAINID.OPTIMISTIC_MAINNET]: '0xB49c4e680174E331CB0A7fF3Ab58afC9738d5F8b',
  [CHAINID.ARBITRUM_MAINNET]: '0xbf22f0f184bCcbeA268dF387a49fF5238dD23E40',
};
export const stargateLPStakingAddress = {
  // leave StargateConfig as '' if you want to deploy a router with deployments
  [CHAINID.ETH_MAINNET]: '0xB0D502E938ed5f4df2E681fE6E419ff29631d62b',
  [CHAINID.OPTIMISTIC_MAINNET]: '0x4DeA9e918c6289a52cd469cAC652727B7b412Cd2',
  [CHAINID.BSC_MAINNET]: '0x3052A0F6ab15b4AE1df39962d5DdEFacA86DaB47',
  [CHAINID.POLYGON_MAINNET]: '0x8731d54E9D02c286767d56ac03e8037C07e01e98',
  [CHAINID.FANTOM_MAINNET]: '0x224D8Fd7aB6AD4c6eb4611Ce56EF35Dec2277F03',
  [CHAINID.ARBITRUM_MAINNET]: '0xeA8DfEE1898a7e0a59f7527F076106d7e44c2176',
  [CHAINID.AVALANCH_MAINNET]: '0x8731d54E9D02c286767d56ac03e8037C07e01e98',
  // // TESTNETS
  // [CHAINID.ETH_RINKEBY]: '0x82A0F5F531F9ce0df1DF5619f74a0d3fA31FF561',
  // [CHAINID.BSC_TESTNET]: '0xbB0f1be1E9CE9cB27EA5b0c3a85B7cc3381d8176',
  // // [CHAINID.Fuji]: "0x13093E05Eb890dfA6DacecBdE51d24DabAb2Faa1",
  // // [CHAINID.Mumbai]: "0x817436a076060D158204d955E5403b6Ed0A5fac0",
  // [CHAINID.ARBITRUM_TESTNET]: '0x6701D9802aDF674E524053bd44AA83ef253efc41',
  // [CHAINID.OPTIMISTIC_TESTNET]: '0xCC68641528B948642bDE1729805d6cf1DECB0B00',
  // [CHAINID.FANTOM_TESTNET]: '0xa73b0a56B29aD790595763e71505FCa2c1abb77f',
};
// WETH、USDC、USDT、[ATOKEN...]
export const BalancesAddresses = {
  [CHAINID.ARBITRUM_MAINNET]: {
    address: [
      LiquidityBridgeTokens.ETH[CHAINID.ARBITRUM_MAINNET],
      LiquidityBridgeTokens.USDC[CHAINID.ARBITRUM_MAINNET],
      LiquidityBridgeTokens.USDT[CHAINID.ARBITRUM_MAINNET],
    ],
    feeUSD: 0.5,
    gasLimit: 5,
  },
};
export const BalancesAmount = {
  [CHAINID.ARBITRUM_MAINNET]: {
    feeUSD: '0.1',
  },
  [CHAINID.OPTIMISTIC_MAINNET]: {
    feeUSD: '0.1',
  },
};
