import { CHAINID } from 'config/constants/chain_config';
import addresses from 'config/constants/contracts';
import tokens, { DEFAULT_Token } from 'config/constants/tokens';
import { getAddress } from 'ethers/lib/utils';

export const getCakeAddress = (chain: CHAINID) => {
  return tokens[chain].kaco.address;
};
export const getMasterChefAddress = (chain: CHAINID) => {
  return addresses.masterChef[chain];
};
export const getwethAddress = (chain: CHAINID) => {
  return DEFAULT_Token[chain].address;
};

export const getFarmAuctionAddress = (chain: CHAINID) => {
  return addresses.farmAuction[chain];
};
// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}
