import BigNumber from 'bignumber.js';
import { CHAINID } from 'config/constants/chain_config';
import { Contract, ethers } from 'ethers';
import { IChainItemType } from 'lifiConfig/IChainType';
import { IMultiTokenItem, SafeAddress } from 'state/types';
import { IVault } from 'state/vault/types';
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber';
import Tokens from 'lifiConfig/tokens.json';
import {
  fetchVaultABIAmountMultiABI,
  MultiSendABI,
  SmartDepositorABI,
  StargateLPStakingABI,
  StargateRouterABI,
  StargateRouterETHABI,
} from 'config/vault/abi';
import { getUserOperationV2 } from './get/getUserOperationV2';
import {
  MultiSend,
  sigValidationModuleAddress,
  SmartDepositor,
  stargateLPStakingAddress,
  stargateRouterAddress,
  stargateRouterETHAddress,
} from 'config/vault/address';
import { ZERO_ADDRESS } from 'state/safeContract/utils/utils';
import { callWithEstimateGas } from 'wallet/estimateGas';
import { getSigner } from 'wallet/getContract';
import { calculateGasMargin } from 'utils/calculateGasMargin';
import { BASE_URL } from 'config';
import { bridgeList, getLifiQuote, IRouteList } from './get/getLifiRoutes';
import { LIFI_Diamond_Facet_Address } from 'lifiConfig/constants';
import { Dispatch } from 'react';
import { ActionType, TaskStatus, SubTaskType } from 'state/vaultHistory/types';
import { IStep } from '../components/RouterComp';
import { fetchGetProcess } from 'state/global/actions';
import { RPCApi } from 'server/RPCApi';
import balanceOf_abi from 'config/abi/balanceOf.json';
import getNodeUrl from 'wallet/getRpcUrl';

export const onPressCallDepositV2 = async ({
  chosedVault,
  fromchain,
  tochain,
  fromtoken,
  toToken,
  safeAddress,
  value,
  account,
  fromchainId,
  tochainId,
  erc20Contract,
  library,
  chosedIndex,
  routeList,
  dispatch,
  endValue,
  fromdappname,
  todappname,
  isQuoteFail,
}: {
  chosedVault: IVault;
  fromchain: IChainItemType;
  tochain: IChainItemType;
  fromtoken: IMultiTokenItem;
  toToken: IMultiTokenItem;
  safeAddress: SafeAddress;
  account: string;
  fromchainId: CHAINID;
  tochainId: CHAINID;
  value: string;
  erc20Contract: Contract;
  library: any;
  chosedIndex: number;
  routeList: IRouteList[];
  dispatch: Dispatch<any>;
  endValue: string;
  fromdappname: string;
  todappname: string;
  isQuoteFail?: boolean;
}) => {
  try {
    const amountIn = new BigNumber(`${value}`).times(BIG_TEN.pow(fromtoken.decimals)).toFixed(0);
    const fromAvatar = safeAddress[account][fromchainId];
    const toAvatar = safeAddress[account][tochainId];
    const wantaddress = chosedVault.vault ? chosedVault.vault.wantaddress : chosedVault.stake.wantaddress;
    const decodeArr = [];
    const actionArr = [];
    const subTasks = [];
    let executionDuration = 0;
    const amountUSD = new BigNumber(value).times(fromtoken.priceUSD);
    const baseexInfo: any = {
      fromtokenlogo: fromtoken.logoURI,
      fromtokenaddress: fromtoken.address,
      fromdecimals: fromtoken.decimals,
      endvalue: endValue,
      startvalue: value,
      fromtokensymbol: fromtoken.symbol,
      fromchain: fromchain.name,
      fromChainId: fromchainId,

      totokenaddress: wantaddress,
      totokenaddresslogo: toToken.logoURI,
      totokensymbol: toToken.symbol,
      toDecimals: toToken.decimals,

      fromdappname: fromdappname,
      todappname: todappname,
      tochain: tochain.name,
      tochainId: tochainId,
    };
    // fromchain
    actionArr.push({
      actionType: ActionType.DEPOSIT,
      tokenAddress: fromtoken.address,
      amountToken: amountIn,
      amountUSD: amountUSD,
      vendor: account,
      exInfo: baseexInfo,
    });
    const [isBalanceEnough, needParantBalance] = await balanceEnough(
      fromtoken,
      value,
      safeAddress,
      account,
      fromchainId,
    );

    const json = {
      taskStatus: TaskStatus.PROCESSING,
      srcAddress: account,
      createTime: Math.floor(Date.now() / 1000),
      subTasks: subTasks,
    };

    // --------build subTask for fromChain--------
    // actions may contained: Approve, LIFI(Swap for gas), LIFI(Swap + Bridge + Swap at toChain)
    // const action = routeList[chosedIndex];
    // const lifiStep = action.steps.filter((v) => v.type === 'lifi');
    // if (lifiStep.length) {
    //   if (bridgeList.includes(lifiStep[0].tool)) {
    //     bridge = [lifiStep[0].tool];
    //   }
    // }
    let _estimateToChainToAmount: string = amountIn;
    const [lifiApproved1, lifiApproved1Obj] = approveEncode(fromtoken.address, LIFI_Diamond_Facet_Address, amountIn);
    if (fromchainId !== tochainId || fromtoken.address.toLowerCase() !== wantaddress.toLowerCase()) {
      // 默认step0在其他链
      const _decodeArr = [];

      const fromTokenAddress = fromtoken.address;
      const step0Params = {
        fromAddress: fromAvatar,
        toAddress: toAvatar,
        fromChainId: fromchainId,
        toChainId: tochainId,
        fromTokenAddress: fromTokenAddress,
        toTokenAddress: wantaddress,
        fromAmount: amountIn,
        bridge: [],
      };
      const [
        [step0lifid1, step0lifid1obj],
        [step0d2, step0step_, , step0_executionDuration, step0gasCostsAmountUSD, _estimateToAmount],
        // [step0d2, step0step_, step0chainId, step0_executionDuration, step0gasCostsAmountUSD, step0_toAmount],
      ] = await getLifiDecode(step0Params);

      _estimateToChainToAmount = _estimateToAmount;

      if (fromTokenAddress !== ZERO_ADDRESS) {
        _decodeArr.push(lifiApproved1);
      }
      if (step0lifid1) {
        _decodeArr.push(step0lifid1);
        _decodeArr.push(step0d2);
        // _decodeArr = [step0d2, step0lifid1] as string[];
      } else {
        _decodeArr.push(step0d2);
        // _decodeArr = [step0d2] as string[];
      }
      const params = allEncode(_decodeArr);

      const { userOperation: fromChainUserOperation, nonce: fromChainNonce } = await getUserOperationV2({
        data: params,
        tochainId: Number(fromchainId),
        _value: '0',
        sigValidationModuleAddress: sigValidationModuleAddress[fromchainId],
        library,
        account,
        chainkey: fromchainId,
        gasToken: ZERO_ADDRESS,
        gasTokenAmount: '0',
        avatar: fromAvatar,
        _callTo: MultiSend[fromchainId],
      });
      baseexInfo.gastotalfee = step0gasCostsAmountUSD;
      baseexInfo.arrivaltotaltime = step0_executionDuration;
      executionDuration = executionDuration + Number(step0_executionDuration);

      subTasks.push({
        type: SubTaskType.DELEGATETX,
        txHash: '',
        taskStatus: TaskStatus.PENDING,
        startTime: Math.floor(Date.now() / 1000),
        userOperation: fromChainUserOperation,
        nonce: fromChainNonce,
        actions: [
          fromTokenAddress !== ZERO_ADDRESS ? lifiApproved1Obj : {},
          step0lifid1obj ?? {},
          {
            actionType: ActionType.BRIDGE,
            tokenAddress: fromtoken.address,
            amountToken: amountIn,
            amountUSD: amountUSD,
            vendor: 'LIFI',
            exInfo: baseexInfo,
          },

          step0Params,
          ...step0step_,
        ],
      });
    }

    // --------build subTask for toChain--------
    // actions may contained: Approve, LIFI(Swap), Approve, Deposit
    // for now, ignore Approve and LIFI(Swap)
    if (chosedVault.vault) {
      const [d1, d1Obj] = approveEncode(wantaddress, chosedVault.contractAddress, _estimateToChainToAmount);
      decodeArr.push(d1);
      actionArr.push(d1Obj);
      const [d2, d2Obj] = depositEncode(
        fromchainId,
        tochainId,
        chosedVault.contractAddress,
        wantaddress,
        toAvatar,
        _estimateToChainToAmount,
      );
      decodeArr.push(d2);
      actionArr.push(d2Obj);
    } else if (chosedVault.stake) {
      if (wantaddress !== ZERO_ADDRESS) {
        const [d1, d1Obj] = approveEncode(wantaddress, chosedVault.stake.routerAddress, _estimateToChainToAmount);
        decodeArr.push(d1);
        actionArr.push(d1Obj);
      }

      const [d2, d2Obj] = stargateAddLiqudityEncode(
        tochainId,
        wantaddress, // wantAddress
        chosedVault.stake.poolid,
        toAvatar,
        _estimateToChainToAmount,
      );
      decodeArr.push(d2);
      actionArr.push(d2Obj);

      const [d3, d3Obj] = approveEncode(chosedVault.stake.pooladdress, stargateLPStakingAddress[tochainId], '0');
      decodeArr.push(d3);
      actionArr.push(d3Obj);

      const [d4, d4Obj] = stargateLPStakingEncode(
        tochainId,
        chosedVault.stake.pooladdress,
        chosedVault.stake.pid,
        new BigNumber(_estimateToChainToAmount)
          .multipliedBy(chosedVault.stake.amountLPtoLDFromNumber)
          .div(chosedVault.stake.amountLPtoLD)
          .toFixed(0),
      );
      decodeArr.push(d4);
      actionArr.push(d4Obj);
    }

    const params = allEncode(decodeArr);
    const { userOperation, nonce } = await getUserOperationV2({
      data: params,
      tochainId: Number(tochainId),
      _value: '0',
      sigValidationModuleAddress: sigValidationModuleAddress[tochainId],
      library,
      account,
      chainkey: tochainId,
      gasToken: ZERO_ADDRESS,
      gasTokenAmount: '0',
      avatar: toAvatar,
      _callTo: MultiSend[tochainId],
    });
    actionArr.push({
      actionType: ActionType.DEPOSIT,
      tokenAddress: toToken.address,
      amountToken: amountIn,
      amountUSD: amountUSD,
      vendor: account,
    });
    subTasks.push({
      type: fromchainId !== tochainId ? SubTaskType.BRIDGETOTX : SubTaskType.DELEGATETX,
      txHash: '',
      taskStatus: TaskStatus.PENDING,
      startTime: Math.floor(Date.now() / 1000),
      userOperation: userOperation,
      actions: actionArr,
      bridgeTime: executionDuration,
      nonce,
    });

    // --------build subTask for USERTX--------
    if (!isBalanceEnough) {
      // 转账给子账号
      const avatar = fromchainId === tochainId ? toAvatar : fromAvatar;
      const transferParams = {
        fromchainId: fromchainId,
        fromtokenAddress: fromtoken.address,
        avatar: avatar,
        account: account,
        needParantBalance: needParantBalance,
      };
      const _res = await transferErc20(
        library,
        transferParams.fromchainId,
        transferParams.fromtokenAddress,
        erc20Contract,
        transferParams.avatar,
        transferParams.account,
        transferParams.needParantBalance,
      );
      if (_res?.isOk && _res?.tx?.hash) {
        subTasks.unshift({
          type: SubTaskType.USERTX,
          txHash: _res.tx.hash,
          taskStatus: TaskStatus.PROCESSING,
          startTime: Math.floor(Date.now() / 1000),
          chainId: fromchainId,
          actions: [
            {
              actionType: ActionType.TRANSFER,
              tokenAddress: transferParams.fromtokenAddress,
              amountToken: transferParams.needParantBalance,
              amountUSD: needParantBalance,
              vendor: '',
              exInfo: {
                ...transferParams,
                ...baseexInfo,
              },
            },
          ],
        });
      } else {
        throw Error('Transfer failed, task aborted');
      }
    }

    //send the Task to Server
    let res = await fetchAvaultServer(json);
    if (res) {
      if (res.status === 200) {
        dispatch(fetchGetProcess({ account }));
      } else if (res.status === 400) {
        res = await fetchAvaultServer(json);
      }
      return {
        res: res,
        isOk: true,
        key: res.data,
      };
    } else {
      return {
        isOk: false,
        message: 'Transfer Error!!',
      };
    }
  } catch (e: any) {
    if (!isQuoteFail && e && e.message && e.message === 'Sorry, could not find available route') {
      onPressCallDepositV2({
        chosedVault,
        fromchain,
        tochain,
        fromtoken,
        toToken,
        safeAddress,
        value,
        account,
        fromchainId,
        tochainId,
        erc20Contract,
        library,
        chosedIndex,
        routeList,
        dispatch,
        endValue,
        fromdappname,
        todappname,
        isQuoteFail: true,
      });
    } else {
      console.log('onPressCallDepositV2', e);
      return {
        isOk: false,
        message: e?.message,
      };
    }
  }
};

const balanceEnough = async (
  token: IMultiTokenItem,
  value: string,
  safeAddress: SafeAddress,
  account: string,
  fromchainId: CHAINID,
): Promise<[boolean, string]> => {
  // const parentBalance = token.balances[account.toLowerCase()]?.number || '0';
  const childBalance = await getBalance(safeAddress[account][fromchainId], fromchainId, token.address);
  const bol = new BigNumber(childBalance).gt(new BigNumber(value).times(BIG_TEN.pow(token.decimals)));
  let balance = BIG_ZERO;
  if (!bol) {
    balance = new BigNumber(value).times(BIG_TEN.pow(token.decimals)).minus(childBalance);
  }
  return [new BigNumber(childBalance).gt(new BigNumber(value).times(BIG_TEN.pow(token.decimals))), balance.toFixed(0)];
};
// Approve：给Vault去操作A Token子账号的余额 买Vault
export const approveEncode = (wantAddress: string, toAddress: string, amountIn: string | ethers.BigNumber) => {
  const _iface_enalbleModule = new ethers.utils.Interface(['function approve(address, uint256) public']);
  const _approveCalldata = _iface_enalbleModule.encodeFunctionData('approve', [toAddress, amountIn]);
  const _d = ethers.utils.solidityPack(
    ['uint8', 'address', 'uint256', 'uint256', 'bytes'],
    [0, wantAddress, 0, ethers.utils.hexDataLength(_approveCalldata), _approveCalldata],
  );
  const obj = {
    actionType: ActionType.APPROVE,
    tokenAddress: wantAddress,
    amountToken: amountIn,
    exInfo: {
      toTokenAddress: wantAddress,
      toAmountToken: amountIn,
    },
  };
  return [_d, obj];
};
// deposit
//todo refactor by SmartDepositor
const depositEncode = (
  fromChainId: CHAINID,
  toChainId: CHAINID,
  aavault: string,
  wantAddress: string,
  account: string,
  amountIn: string,
) => {
  const _iface_enalbleModule = new ethers.utils.Interface(fetchVaultABIAmountMultiABI);
  const _depositToAave = _iface_enalbleModule.encodeFunctionData('deposit', [account, amountIn]);

  const _iface_depositor = new ethers.utils.Interface(SmartDepositorABI);
  const _calldata = _iface_depositor.encodeFunctionData('deposit', [
    wantAddress,
    amountIn,
    aavault,
    4 + 32 * 1,
    _depositToAave,
  ]);

  const _d1 = ethers.utils.solidityPack(
    ['uint8', 'address', 'uint256', 'uint256', 'bytes'],
    [1, SmartDepositor[toChainId], 0, ethers.utils.hexDataLength(_calldata), _calldata],
  );

  const obj = {
    actionType: ActionType.DEPOSIT,
    tokenAddress: aavault,
    amountToken: amountIn,
    exInfo: {
      fromChainId: fromChainId,
      destChainId: toChainId,
      toAddress: aavault,
      toAmountToken: amountIn,
    },
  };
  return [_d1, obj];
};

export const stargateLPStakingEncode = (
  toChainId: CHAINID,
  wantAddress: string, //stargate LPToken
  pid: number,
  amountIn: string | ethers.BigNumber,
) => {
  const _iface_stargate = new ethers.utils.Interface(StargateLPStakingABI);
  const _stargateAction = _iface_stargate.encodeFunctionData('deposit', [pid, amountIn]);

  const _iface_depositor = new ethers.utils.Interface(SmartDepositorABI);
  const _calldata = _iface_depositor.encodeFunctionData('deposit', [
    wantAddress,
    amountIn,
    stargateLPStakingAddress[toChainId],
    4 + 32 * 1,
    _stargateAction,
  ]);

  const _d1 = ethers.utils.solidityPack(
    ['uint8', 'address', 'uint256', 'uint256', 'bytes'],
    [1, SmartDepositor[toChainId], 0, ethers.utils.hexDataLength(_calldata), _calldata],
  );
  const obj = {
    actionType: ActionType.DEPOSIT,
    tokenAddress: wantAddress,
    amountToken: amountIn,
    vendor: 'Stargate-Farm',
    exInfo: {
      pid: pid,
    },
  };
  return [_d1, obj];
};

export const stargateAddLiqudityEncode = (
  toChainId: CHAINID,
  wantAddress: string,
  poolId: number,
  account: string,
  amountIn: string,
) => {
  let _calldata: string;
  if (wantAddress === ZERO_ADDRESS) {
    const _iface_stargate = new ethers.utils.Interface(StargateRouterETHABI);
    const _stargateAddLiquidity = _iface_stargate.encodeFunctionData('addLiquidityETH', []);

    const _iface_depositor = new ethers.utils.Interface(SmartDepositorABI);
    _calldata = _iface_depositor.encodeFunctionData('deposit', [
      wantAddress,
      amountIn,
      stargateRouterETHAddress[toChainId],
      0,
      _stargateAddLiquidity,
    ]);
  } else {
    const _iface_stargate = new ethers.utils.Interface(StargateRouterABI);
    const _stargateAddLiquidity = _iface_stargate.encodeFunctionData('addLiquidity', [poolId, amountIn, account]);

    const _iface_depositor = new ethers.utils.Interface(SmartDepositorABI);
    _calldata = _iface_depositor.encodeFunctionData('deposit', [
      wantAddress,
      amountIn,
      stargateRouterAddress[toChainId],
      4 + 32 * 1,
      _stargateAddLiquidity,
    ]);
  }

  const _d1 = ethers.utils.solidityPack(
    ['uint8', 'address', 'uint256', 'uint256', 'bytes'],
    [1, SmartDepositor[toChainId], 0, ethers.utils.hexDataLength(_calldata), _calldata],
  );
  const obj = {
    actionType: ActionType.DEPOSIT,
    tokenAddress: wantAddress,
    amountToken: amountIn,
    vendor: 'Stargate-Pool',
    exInfo: {
      poolId: poolId,
    },
  };
  return [_d1, obj];
};

export const getBalance = async (account: string, chain: CHAINID, address: string) => {
  if (address && address !== ZERO_ADDRESS) {
    return await getErc20Balance(account, chain, address);
  }
  return await getNativeBalance(account, chain);
};
const getErc20Balance = async (account: string, chain: CHAINID, address: string) => {
  const rpcApi = new RPCApi({
    chainkey: chain,
  });
  const vaultCall = [
    {
      address: address,
      name: 'balanceOf',
      params: [account],
    },
  ];
  const result = await rpcApi.multicall(balanceOf_abi, vaultCall);
  return result[0][0].toString();
};
const getNativeBalance = async (account: string, chain: CHAINID) => {
  const rpcApi = new RPCApi({
    chainkey: chain,
  });
  const { multicallAddress } = getNodeUrl(chain);
  const vaultCall = [
    {
      address: multicallAddress,
      name: 'getEthBalance',
      params: [account],
    },
  ];
  const result = await rpcApi.multicall(balanceOf_abi, vaultCall);
  return result[0][0].toString();
};

export const allEncode = (params: string[]) => {
  const _multiSend = new ethers.utils.Interface(MultiSendABI);
  const _finalCalldata = _multiSend.encodeFunctionData('multiSend', [ethers.utils.hexConcat(params)]);
  return _finalCalldata;
};

export const transferErc20 = async (
  library: any,
  chainId: CHAINID,
  fromAddress: string,
  erc20Contract: Contract,
  avatar: string,
  account: string,
  amountIn: string,
) => {
  let res;
  if (!fromAddress || fromAddress === ZERO_ADDRESS) {
    res = await sendTransaction(library, account, amountIn, avatar);
  } else {
    res = await callWithEstimateGas(chainId, erc20Contract, 'transfer', [avatar, `${amountIn}`], {}, true);
  }
  return res;
};

const sendTransaction = async (
  library: any,
  account: string,
  amount: string,
  to: string,
): Promise<ethers.providers.TransactionResponse | any> => {
  try {
    const signer = getSigner(library, account);
    const estimatedGas = await signer.estimateGas({
      value: amount,
      to: to,
    });
    const tx = await signer.sendTransaction({
      gasLimit: calculateGasMargin(estimatedGas),
      value: amount,
      to: to,
    });
    if (tx) {
      return {
        tx: tx,
        isOk: true,
      };
    } else {
      return {
        isOk: false,
        message: 'Some Error',
      };
    }
  } catch (e: any) {
    return {
      isOk: false,
      message: e?.message,
    };
  }
};

export const fetchAvaultServer = async (params) => {
  const res = await fetch(BASE_URL + '/api/v0/task', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  const r = await res.json();
  return r;
};
export const fetchToken = async (chain: CHAINID, tokenAddress: string) => {
  try {
    const TokensObj = Tokens.tokens[chain].filter((v) => `${v.address.toLowerCase()}` === tokenAddress.toLowerCase());
    if (TokensObj) {
      return TokensObj[0];
    } else {
      const res = await fetch(`https://li.quest/v1/token?chain=${chain}&token=${tokenAddress}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const r = await res.json();
      return r;
    }
  } catch {
    return {
      address: tokenAddress,
      chainId: chain,
      priceUSD: 1,
    };
  }
};
// function sleep(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }
export const getLifiDecode = async ({
  fromAddress,
  toAddress,
  fromChainId,
  toChainId,
  fromTokenAddress,
  toTokenAddress,
  fromAmount,
  bridge,
}: {
  fromAddress: string;
  toAddress: string;
  fromChainId: CHAINID;
  toChainId: CHAINID;
  fromTokenAddress: string;
  toTokenAddress: string;
  fromAmount: string;
  bridge: string[];
}) => {
  const lifiParmas: any = {
    fromChain: fromChainId,
    fromToken: fromTokenAddress,
    fromAddress: fromAddress,
    fromAmount: fromAmount,
    toToken: toTokenAddress,
    toChain: toChainId,
    toAddress: toAddress,

    // slippage: '0.005',
    // order: 'RECOMMENDED',
    // preferBridges: ['stargate'],
    // allowBridges: ['stargate'],
    // denyBridges: ['hop', 'connext'],
    // denyBridges: ['hop'],
  };
  if (bridge.length) {
    if (bridgeList.includes(bridge[0]) && fromAddress !== ZERO_ADDRESS && toAddress !== ZERO_ADDRESS) {
      lifiParmas.preferBridges = bridge;
      // lifiParmas.allowBridges = bridge;
    }
  }
  let lifi = await getLifiQuote(lifiParmas);
  let k = 0;
  const _maxRetry = 3;
  if (!lifi?.transactionRequest || !lifi?.transactionRequest?.data) {
    while (!lifi?.transactionRequest && k < _maxRetry) {
      k++;
      lifi = await getLifiQuote(lifiParmas);
    }
    if (k === _maxRetry && !lifi?.transactionRequest.data) {
      throw Error('Sorry, could not find available route');
    }
  }
  let lifiCalldata = lifi.transactionRequest.data;
  // 如果子账号native资产不足，则从fromtoken.address swap 一定量给原生资产

  let _ethValue = new BigNumber(lifi.transactionRequest.value).toFixed(0);
  const nativeBalance = await getNativeBalance(fromAddress, fromChainId);

  let lifid1 = undefined;
  let lifid1obj = undefined;
  if (_ethValue !== '0' && new BigNumber(_ethValue).gt(nativeBalance)) {
    if (fromTokenAddress !== ZERO_ADDRESS) {
      const needBalance = new BigNumber(_ethValue).minus(nativeBalance);
      // 折合u
      const nativeToken = await fetchToken(fromChainId, ZERO_ADDRESS);
      //  @ts-ignore
      const needBalanceUSD = new BigNumber(nativeToken.priceUSD).times(needBalance.shiftedBy(-nativeToken.decimals));
      const fromToken = await fetchToken(fromChainId, fromTokenAddress);
      //  @ts-ignore
      const needAmount = needBalanceUSD
        .div(fromToken.priceUSD)
        .times(BIG_TEN.pow(fromToken.decimals))
        .times(1.1)
        .toFixed(0);

      if (needAmount !== '0') {
        lifid1obj = {
          fromChain: fromChainId,
          fromToken: fromTokenAddress,
          fromAddress: fromAddress,
          fromAmount: needAmount,
          toToken: ZERO_ADDRESS,
          toChain: fromChainId,
          toAddress: fromAddress,
        };

        const needBalanceLifi = await getLifiQuote(lifid1obj);
        lifid1obj.toAmount = needBalanceLifi.estimate.toAmount;
        lifid1obj.toAmountMin = needBalanceLifi.estimate.toAmountMin;
        const _lifiCalldata = needBalanceLifi.transactionRequest.data;
        lifid1 = ethers.utils.solidityPack(
          ['uint8', 'address', 'uint256', 'uint256', 'bytes'],
          [0, LIFI_Diamond_Facet_Address, 0, ethers.utils.hexDataLength(_lifiCalldata), _lifiCalldata],
        );
      }

      const _fromAmount = new BigNumber(fromAmount).minus(new BigNumber(needAmount)).minus(1).toFixed(0);
      if (new BigNumber(_fromAmount).lte(BIG_ZERO)) {
        throw Error('Not enough balance to pay bridge gas');
      }
      lifiParmas.fromAmount = _fromAmount;
    } else {
      const bridgeGasValue = new BigNumber(_ethValue).minus(fromAmount).multipliedBy(1.1).toFixed(0);
      const maxFromAmount = new BigNumber(fromAmount).minus(bridgeGasValue).toString();
      if (new BigNumber(maxFromAmount).lte(0)) {
        throw new Error('not enough balance');
      }
      lifiParmas.fromAmount = maxFromAmount;
    }
    lifi = await getLifiQuote(lifiParmas);
    lifiCalldata = lifi.transactionRequest.data;
    _ethValue = new BigNumber(lifi.transactionRequest.value).toFixed(0);
  }
  // const
  const d2 = ethers.utils.solidityPack(
    ['uint8', 'address', 'uint256', 'uint256', 'bytes'],
    [0, LIFI_Diamond_Facet_Address, _ethValue, ethers.utils.hexDataLength(lifiCalldata), lifiCalldata],
  );
  const step_: IStep[] = [];
  for (let i = 0; i < lifi.includedSteps.length; i++) {
    const _step = lifi.includedSteps[i];
    if (_step.type === 'swap' || _step.type === 'cross' || _step.type === 'custom') {
      const gasCostsAmountUSD = _step.estimate.gasCosts
        .map((v) => v.amountUSD)
        .reduce((prev, cur) => Number(prev) + Number(cur), 0);
      const _time = _step.estimate.executionDuration;

      step_.push({
        tool: _step.tool,
        type: _step.type,
        toolDetails: _step.toolDetails,
        gasCostsAmountUSD: `${gasCostsAmountUSD}`,
        fromChainId: _step.action.fromChainId,
        fromAmount: _step.action.fromAmount,
        toAmount: _step.estimate.toAmountMin,
        fromToken: _step.action.fromToken,
        toChainId: _step.action.toChainId,
        toToken: _step.action.toToken,
        time: `${_time}`,
      });
    } else if (_step.type === 'lifi') {
      for (let j = 0; j < _step.includedSteps.length; j++) {
        const __step = _step.includedSteps[j];
        const _time = __step.estimate.executionDuration;
        const gasCostsAmountUSD = __step.estimate.gasCosts
          .map((v) => v.amountUSD)
          .reduce((prev, cur) => Number(prev) + Number(cur), 0);
        step_.push({
          tool: __step.tool,
          type: __step.type,
          toolDetails: __step.toolDetails,
          gasCostsAmountUSD: `${gasCostsAmountUSD}`,
          fromChainId: __step.action.fromChainId,
          fromAmount: __step.action.fromAmount,
          toAmount: __step.estimate.toAmount,
          fromToken: __step.action.fromToken,
          toChainId: __step.action.toChainId,
          toToken: __step.action.toToken,
          time: `${_time}`,
        });
      }
    }
  }
  const gasCostsAmountUSD = step_.map((v) => v.gasCostsAmountUSD).reduce((prev, cur) => Number(prev) + Number(cur), 0);

  const time = step_.map((v) => v.time).reduce((prev, cur) => Number(prev) + Number(cur), 0);
  return [
    [lifid1, lifid1obj],
    [d2, step_, lifi.transactionRequest.chainId, time, gasCostsAmountUSD, lifi.estimate.toAmount],
  ];
};
