import { STATIC_BASE_URL } from 'config';
import { PageMeta } from './types';

export const routePath = {
  home: {
    path: '/',
    text: '',
    title: 'Avault | Avault',
  },
  vault: {
    path: '/multi/vault',
    text: 'Vault',
    title: 'Multi Vault | Avault',
  },
  vault_trade_deposit: {
    pathPre: '/multi/vault/trade/deposit',
    path: '/multi/vault/trade/deposit/:vaultChain/:contract_address',
    text: 'Vault Trade',
    title: 'Multi Vault Trade | Avault',
  },
  vault_trade_withdraw: {
    pathPre: '/multi/vault/trade/withdraw',
    path: '/multi/vault/trade/withdraw/:vaultChain/:contract_address',
    text: 'Vault Trade',
    title: 'Multi Vault Trade | Avault',
  },
  trade_withdraw: {
    pathPre: '/trade/withdraw',
    path: '/trade/withdraw/:safeContractAddress/:fromchain/:fromtoken',
    text: 'Trade Withdraw',
    title: 'Trade Withdraw | Avault',
  },
  claim: {
    pathPre: '/multi/vault/trade/claim',
    path: '/multi/vault/trade/claim/:vaultChain/:contract_address',
    text: 'Trade Claim',
    title: 'Trade Claim | Avault',
  },

  vault_transaction_list: {
    path: '/multi/transaction/list',
    text: 'Vault Transaction List',
    title: 'Transaction List | Avault',
  },

  // vault_transaction_list_detail: {
  //   pathPre: '/multi/transaction/list',
  //   path: '/multi/transaction/list/:fromtx',
  //   text: 'Vault Transaction List Detail',
  //   title: 'Transaction List | Avault',
  // },
};

export const DEFAULT_META: PageMeta = {
  title: 'Avault',
  description: 'Lower the threshold for users to use Web 3.0 DeFi Dapps!!!',
  image: `${STATIC_BASE_URL}/images/hero.png`,
};

export const getCustomMeta = (path: string): PageMeta => {
  if (path.indexOf(routePath.vault_trade_deposit.pathPre) > -1) {
    return {
      title: routePath.vault_trade_deposit.title,
    };
  } else if (path.indexOf(routePath.vault_trade_withdraw.pathPre) > -1) {
    return {
      title: routePath.vault_trade_withdraw.title,
    };
  } else if (path.indexOf(routePath.vault_transaction_list.path) > -1) {
    return {
      title: routePath.vault_transaction_list.title,
    };
  }
  return {
    title: `Vault | Avault`,
  };
};
